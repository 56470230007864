// ------------------------------------------------ checkbox & radio
.hikashop_filter_checkbox {
  @include flexbox;
  @include align-items(center);
  margin-bottom: 8px;
}

.hikashop_filter_main_div {
  position: sticky;
  top: 120px;
  background-color: var(--gray-2);
  padding: 1rem;

  br {
    display: none;
  }

  select {
    height: 50px !important;
    font-size: 1rem !important;
  }

  .hikashop_filter_main {
    width: 100% !important;
    float: none !important;
    margin-bottom: 1rem;
    max-height: 300px;
    overflow: auto;
  }

  .hikashop_filter_title {
    display: block;
    font-size: rem(18);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .btn {
    font-size: 1rem;
  }

  .hikashop_reset_button_outside {
    .btn {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

// ------------------------------------------------ FILTRE MOBILE

.hikashop_filter_collapsable_title {
  @include flexbox;
  @include align-items(center);
  cursor: pointer;
  padding: 0 1rem;
  .hikashop_filter_collapsable_title_icon {
    margin-right: 7px;
  }
}

// ------------------------------------------------ EO FILTRE MOBILE

.filter_refresh_div {
  // @include flexbox;
}

@include breakpoint(1080) {
  .hikashop_filter_main_div {
    position: relative;
    top: 0;
  }
  div.hikashop_filter_collapsable_content_mobile {
    // display: none;
  }
}

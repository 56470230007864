.admin--characteristic {
  .hikabtn {
  }

  #market_characteristic_40 {
    display: none !important;
  }

  // ------------------------------------------------ EO VENDEUR
  #filter_vendors,
  #market_characteristic_add_value dt:nth-of-type(2),
  #market_characteristic_add_value dd:nth-of-type(2) {
    // display: none;
  }

  // nom / vendeur
  .hikamarket_characteristic_name_title {
    color: transparent;
    pointer-events: none;
    cursor: none;
    &::before {
      content: "Nom";
      color: initial;
    }
  }
  // supprimer le nom du vendeur en dessous de la caractéristique dans la liset
  .hikamarket_characteristic_name_value {
    div[data-vendor_id] {
      display: none;
    }
  }

  // Quand clic sur caracteristique, le vendeur n'apparait pas
  .hikamarket_characteristic_name_value .nameboxes {
    display: none;
  }

  .hikamarket_characteristic_valuecounter_title,
  .hikamarket_characteristic_usedcounter_title,
  .hikamarket_characteristic_actions_title,
  .hikamarket_characteristic_ordering_title {
    text-align: center;
  }

  .hikamarket_characteristic_valuecounter_value,
  .hikamarket_characteristic_usedcounter_value,
  .hikamarket_characteristic_actions_value,
  .hikamarket_characteristic_ordering_value {
    min-width: 125px;
  }

  // ------------------------------------------------ EO VENDEUR

  .hikabtn {
  }

  .hikam_filter {
    width: 100%;

    td {
      position: relative;
      input {
        // position: absolute;
        top: 0;
        z-index: 1;
        padding-right: 120px;
        margin-bottom: 1rem;
      }
    }
    .hikabtn {
      position: absolute;
      z-index: 10;
      top: 54px;
      transform: translateY(-50%);
      height: 50px;
      width: 50px;
      margin: 0;
      &:nth-of-type(1) {
        right: 55px;
      }
      &:nth-of-type(2) {
        right: 5px;
      }
    }
    td.nowrap {
      display: initital !important;
      &::before {
        content: "fff";
      }
    }
    select {
      // background-position: center center;
      width: initial !important;
    }
  }
}

@include breakpoint(768) {
  .admin--characteristic .hikam_filter select {
    width: 20px !important;
    background-position: center center !important;
  }
}

.hikam_table {
  margin-top: 2rem;
  .hikamarket_characteristic_name_title {
    text-align: left;
  }

  .hikamarket_characteristic_vendor_value,
  .hikamarket_characteristic_valuecounter_value,
  .hikamarket_characteristic_usedcounter_value,
  .hikamarket_characteristic_actions_value,
  .hikamarket_characteristic_id_value {
    text-align: center;
  }

  // --------------------- alias / vendeur / id
  .hikamarket_characteristic_alias_title,
  .hikamarket_characteristic_alias_value,
  .hikamarket_characteristic_brnfot_title,
  .hikamarket_characteristic_vendor_value,
  .hikamarket_characteristic_id_title,
  .hikamarket_characteristic_id_value {
    display: none;
  }
  // -------------------------------------------------- détails category
  .hikamarket_characteristic_ordering_value input {
    max-width: 50px;
    margin: auto;
    padding: 0;
    text-align: center;
  }
  // ---------------- supprime le champ vendeur
  #market_characteristic_vendor_21 {
    // display: none;
  }
}
@include breakpoint(880) {
  .hikam_table .hikamarket_characteristic_name_value::before {
    content: "Nom / Vendeur";
  }
  .hikam_table .hikamarket_characteristic_valuecounter_value::before {
    content: "valeurs";
  }
  .hikam_table .hikamarket_characteristic_usedcounter_value::before {
    content: "Utilisé";
  }
}

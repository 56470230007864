.hikamarket_vendor_edit_page {
  max-width: 700px;

  .hikamarket_vendor_email_line,
  #hikamarket_vendor_vendor_address_telephone {
    label::after {
      content: "(Non visible sur le site)";
      margin-left: 5px;
    }
  }

  // --------------- image

  #hikamarket_vendor_image_addpopup {
    display: none;
  }

  #hikamarket_vendor_image_uploadpopup,
  #vendor_image_exploitation_uploadpopup {
    &::before {
      content: "Télécharger une image";
      margin-right: 7px;
    }
  }
  .fa-stack {
    display: block;
    height: auto;
    line-height: 20px;
    width: auto;
  }
}

// ------ Module

.hikashop_categories_listing_main {
  // --category-height: 250px;
  margin: 2rem 0;
  background-color: white;
  //   -------------------- container
  .hk-row-fluid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    &::before,
    &::after {
      content: none;
    }
  }

  //   -------------------- category

  .hikashop_category {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    height: var(--category-height);
    width: 100% !important;
    .hikashop_category_image {
      position: relative;
      height: var(--category-height) !important;
      margin-bottom: -2rem;

      & a::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../../images/overlay-category.png");
        background-position: center;
        background-size: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .hikashop_category_name {
      color: var(--color-1);
      text-transform: uppercase;
      font-weight: bold;
      font-size: clamp(0.7rem, 0.9vw, 0.9rem);
    }
  }
}

@include breakpoint(1280) {
  .hikashop_categories_listing_main {
    .hk-row-fluid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }
}

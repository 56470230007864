.hkform-group {
  label.hkcontrol-label {
    text-align: left;
    padding: 0;
  }
  .hkc-sm-4,
  .hkc-sm-8 {
    width: 100%;
  }
}

.popover {
  display: none !important;
}

.hikashop_messages {
  // display: none !important;
  color: white !important;
  padding: 5px 1rem !important;
  font-weight: normal !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  margin: 1rem 0 !important;
  border: 0 !important;

  &.hikashop_success {
    background-color: var(--success);
  }

  &.hikashop_error {
    background-color: var(--error) !important;
  }
  &.hikashop_warning {
    background-color: var(--error) !important;
  }
}

// -------------------------------------- message qty max
.hika__check__stock {
  position: absolute;
  left: 50%;
  bottom: 45px;
  z-index: -1;
  background-color: var(--error);
  color: white;
  padding: 8px 16px;
  border-radius: 7px;
  box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.26);
  font-size: 1.2rem;
  opacity: 0;
  transform: translate(-50%, 15px);
  transition: all 0.2s;

  span {
    display: block;
    min-width: 250px;
    text-align: center;
  }
}

.hika__check__stock--active {
  z-index: 10;
  opacity: 1;
  transform: translate(-50%, 0);
}

// --------------------------------------------------- Fox

.alert-success {
  .fox-messages {
    background-color: var(--success);
  }
}

.fox-messages {
  background-color: var(--error);
  color: white;
  padding: 1rem;
}

.close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: rem(50);
  line-height: 1;
  color: white;
  cursor: pointer;
  opacity: 1;
}

// --------------------------------------------------- gix message
.gix_mess {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  top: 1rem;

  svg {
    display: block;
    margin: 0 auto 1rem auto;
  }

  .gix_mess_yes,
  .gix_mess_no,
  .gix_mess_info {
    padding: 1rem 2rem 1rem 1rem;
    border-radius: 7px;
    font-size: rem(20);
    margin: 1rem 0;
    text-align: center;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
  }

  .gix_mess_yes {
    color: white;
    background-color: #15b713;
  }

  .gix_mess_no {
    color: white;
    background-color: #ff6262;
  }

  .gix_mess_info {
  }

  .gix_mess_title,
  .gix_mess_result {
  }

  .gix_mess_title {
    font-weight: bold;
  }

  .gix_mess_result {
  }
}

// --------------------------------------------------- Message joomla

.alert {
  position: relative;
}

.alert-heading {
  color: white !important;
}

.system__message,
#system-message,
#system-message-container {
  position: relative;
  margin: 1rem;
  // position: fixed;
  // left: 50%;
  // transform: translateX(-50%);
  // top: 1rem;
  z-index: 1001;

  .alert-heading {
    display: none;
  }

  .alert {
    padding: 1rem 3.5rem 1rem 1rem;
    border-radius: 7px;
    font-size: rem(20);
    margin: 1rem auto;
    max-width: 980px;
    // padding-right: 3rem;
    text-align: center;
    background-color: var(--success);
    color: white;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
  }

  .alert-danger,
  .alert-error,
  .alert-notice,
  .alert-warning {
    background-color: var(--error);
    color: white;
  }

  .alert-message {
  }

  .close {
    // display: none;
  }
}

.message__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  svg,
  path {
    fill: white;
  }
}

// --------------------------------------------------- Message à côté de l'input

// .msg {
//   position: absolute;
//   left: calc(100% + 12px);
//   bottom: 10px;
//   z-index: 10;
//   line-height: 40px;
//   border-radius: 7px;
//   color: var(--color-light);
//   background-color: var(--color-dark);
//   box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.25);
//   @include arrow(12px, var(--color-dark), var(--color-dark));
// }

// .msg__content {
//   position: relative;
//   display: block;
//   padding: 0 rem(12);
//   white-space: nowrap;
// }

// ----------------------------------- error

// .msg--error {
//   background-color: var(--error);
//   @include arrow(12px, var(--error), var(--error));
// }

// ----------------------------------- valid

// .msg--valid {
//   background-color: var(--color-1);
//   @include arrow(12px, var(--color-1), var(--color-1));
// }

@include breakpoint(1100) {
  .system__message,
  #system-message {
    // top: 130px;
  }
}

@include breakpoint(550) {
  .system__message,
  #system-message {
    // top: 110px;
    .alert {
      padding: 0.5rem 3rem 0.5rem 1rem;
      font-size: rem(16);
    }
  }
}

// =============== voir checkout->layout

.hikashop_checkout_shipping {
  margin-top: 2rem;
  legend {
    // display: none;
  }

  // ------------------------------------------------------------- icons
  label[for="shipping_radio_2_2__0v1__manual_2"]
    .hikashop_checkout_shipping_name::before,
  label[for="shipping_radio_2_2__0v1__mondialrelay_6"]
    .hikashop_checkout_shipping_name::before,
  label[for="shipping_radio_2_2__0v1__manual_4"]
    .hikashop_checkout_shipping_name::before {
    content: "";
    width: 40px;
    height: 40px;
    background-size: cover !important;
  }

  // -------------------------------------------- click and collect
  label[for="shipping_radio_2_2__0v1__manual_2"]
    .hikashop_checkout_shipping_name::before {
    background: url("../images/click-collect.png");
  }
  // -------------------------------------------- mondial relay
  label[for="shipping_radio_2_2__0v1__mondialrelay_6"]
    .hikashop_checkout_shipping_name::before {
    background: url("../images/mondial-relay.svg");
  }
  // -------------------------------------------- colissimo
  label[for="shipping_radio_2_2__0v1__manual_4"]
    .hikashop_checkout_shipping_name::before {
    background: url("../images/colissimo.png");
  }
  // ----------------------- eo icons
}

// ============================================================= MONDIAL RELAY

#hikashop_checkout_shipping_2_2__0v1__6__custom {
  address {
    margin: 1rem 0;
    font-weight: 500;
  }
  tr {
    display: block;
    margin-bottom: 1rem;
  }
  td {
    // display: initial;
    padding: 0;
    margin: 0;
  }

  .mondialrelay_div_map {
    height: 500px !important;
    z-index: -1;
  }
}

.footer {
  position: fixed;
  z-index: 10;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 0 5px 0;
  background-color: white;
  // border-top: 10px solid var(--gray-2);
  padding-right: 1rem;
}

.footer__container {
  @include flexbox;
  @include justify-content(space-between);
  @include flex-flow(row wrap);
  padding: 0.3em 0;

  p {
    @include flexbox;
    @include flex-flow(row wrap);
    gap: 4px;
  }
}

.made__in__france {
  //   position: fixed;
  //   bottom: 1rem;
  //   right: 5rem;
  @include flexbox;
  @include align-items(center);
  gap: 4px;
  img {
    max-height: 16px;
  }
}

.f-mentions,
.f-mentions p,
#gx-social,
#gx-social span,
#gx-social ul,
#gx-social li {
  // display: inline-block;
  // vertical-align: middle;
  // line-height: 40px;
  padding: 0;
}

/* ----- Mentions ----- */
.f-mentions {
  padding-left: 320px;
}
.f-mentions-active .f-mentions {
  padding-left: 120px;
}

.footer.f-mentions-active {
  background-color: var(--gray-2);
}

.f-mentions p {
  font-size: 0.95rem;
}

/* ----- Social ----- */
#gx-social {
  position: absolute;
  right: 40px;
  transition: all 300ms;

  img {
    width: 50px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

#gx-social span {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 2px;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1500px) {
  .f-mentions {
    padding-left: 130px;
  }
  #gx-social {
    right: 50px;
  }
  .f-mentions-active #gx-social {
    right: 40px;
  }
  .f-mentions p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    position: relative;
  }
  .f-mentions-active .f-mentions {
    padding-left: 3%;
  }
  .f-mentions-active #gx-social {
    right: 3%;
  }
}

@media screen and (max-width: 850px) {
  .footer {
    position: relative;
    z-index: 1;
    padding: 0 3%;
    width: auto;

    .f-mentions {
      padding-left: 0;
      display: block;
      p {
        display: block;
        text-align: center;
      }
    }

    #gx-social {
      position: relative;
      width: 100%;
      right: 0;
      text-align: center;
    }
  }
}

.hikashop_product_quantity_div {
  @include flexbox;
  @include align-items(center);

  .add-on {
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background-color: var(--color-1);
    border: 1px solid var(--color-1);
    margin: 0 4px;
    color: white;
    a {
      display: block;
      &:hover {
        color: white;
      }
    }
  }

  .hikashop_product_quantity_field {
    max-width: 46px;
    text-align: center;
    padding: 0;
    max-height: 46px;
    background-color: transparent;
    border: 1px solid var(--color-1);
    color: var(--color-1);
  }
}

.hikashop_address_listing_div {
  #hikashop_user_addresses_default {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 25px;
  }
  .hikashop_address_listing_div {
    .hikashop_billing_addresses {
      padding-right: 25px;
    }
  }

  .address_selection {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid var(--gray-3);
  }

  #hikashop_user_addresses_show {
    margin-top: 2rem;
  }
}

@include breakpoint(1080) {
  .hikashop_address_listing_div #hikashop_user_addresses_default {
    display: block;
    margin-bottom: 1rem;
  }
}

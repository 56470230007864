/* Couleur client */
.mentions-politique a,
.fox_link,
.mentions-politique h2,
#tarteaucitronDisclaimerAlert {
  color: var(--color-1) !important;
  text-decoration: none !important;
}
.titre-nb,
#tarteaucitronPersonalize,
#tarteaucitronPercentage {
  background-color: var(--color-1) !important;
}

#tarteaucitronAlertBig {
  // position: relative !important;
  width: 420px !important;
  left: 1rem !important;
  bottom: 1rem !important;
  padding: 0 !important;
  background: white !important;
  box-shadow: 10px 20px 41px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  max-height: 80vh;
  border: none !important;

  #tarteaucitronDisclaimerAlert {
    display: block !important;
    font-family: var(--font-normal) !important;
    font-size: 0.95rem !important;
    text-align: left !important;
    padding: 1rem !important;

    span {
      display: block !important;
      color: var(--color-1) !important;
      font-family: var(--font-normal) !important;
    }

    .cookies__cute {
      position: absolute;
      right: 0;
      top: 0;
      width: 150px;
    }

    .cookies__hello,
    .cookies__title {
      padding-right: 90px;
    }

    .cookies__hello {
      font-size: 1rem !important;
    }

    .cookies__title {
      font-weight: bold !important;
      font-size: 1.4rem !important;
      margin-bottom: 1rem;
    }
  }

  .cookies__btn {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    border-top: 1px solid #f1f1f1;

    .tarteaucitronDeny,
    #tarteaucitronCloseAlert,
    .tarteaucitronAllow {
      background-color: transparent !important;
      margin: 0 !important;
      font-size: 0.85rem !important;
      font-family: var(--font-normal) !important;
      white-space: normal;
      padding: 1.5rem 0;
    }

    .tarteaucitronDeny {
      color: #333 !important;
    }

    #tarteaucitronCloseAlert {
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
    }

    #tarteaucitronPersonalize2 {
      color: var(--color-1) !important;
      font-weight: 500 !important;
      font-size: 0.9rem !important;
    }

    .tarteaucitronCross,
    .tarteaucitronCheck {
      display: none;
    }
  }
}

// ====================================================== PANNEAU DE GESTION

#tarteaucitronAllAllowed:hover {
  background-color: #1b870b !important;
  opacity: 1;
}

#tarteaucitronAllDenied:hover {
  background-color: #9c1a1a !important;
  opacity: 1;
}

// ============================================= texte

// ---------------------- btn

#tarteaucitronPersonalize {
  // margin: 6rem 0 1rem 0 !important;
}
#tarteaucitronPercentage {
  display: none !important;
}
#tarteaucitronDisclaimerAlert {
  font-family: var(--font-normal) !important;

  b {
    color: var(--color-1) !important;
  }
}

// #tarteaucitronPercentage,
// #tarteaucitronPersonalize {
//   background-color: var(--color-1) !important;
// }

// #tarteaucitronDisclaimerAlert {
//   color: var(--color-1) !important;
//   font-family: var(--font-normal) !important;
// }

@include breakpoint(550) {
  #tarteaucitronAlertBig {
    // position: relative !important;
    width: 100% !important;
    left: 0 !important;
    bottom: 0 !important;
    box-shadow: -5px -5px 10px rgba($color: #000000, $alpha: 0.16);
    border-radius: 0;
  }
  #tarteaucitronAlertBig .cookies__btn .tarteaucitronDeny,
  #tarteaucitronAlertBig .cookies__btn #tarteaucitronCloseAlert,
  #tarteaucitronAlertBig .cookies__btn #tarteaucitronPersonalize2 {
    font-size: 0.75rem !important;
  }
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert .cookies__hello {
    font-size: 0.9rem !important;
  }
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert .cookies__title {
    font-size: 1.2rem !important;
  }
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert .cookies__cute {
    width: 100px !important;
  }
}

// ====================================================== EO COOKIES
/* -------------- */

.mentions-politique {
  margin: 180px 0;
  padding: 0 50px;
}

.mentions-politique h2,
.mentions-politique h3,
.mentions-politique h4 {
  display: block;
  font-family: inherit;
  font-weight: bold;
  letter-spacing: 0;
}

.mentions-politique h2 {
  font-size: 1.2rem;
  margin: 40px 0 15px 0;
  padding: 10px 0 10px 25px;
  background-color: #f1f1f1;
  border-bottom: 3px solid #ccc;
}

.mentions-politique h3 {
  font-size: 1.1rem;
  padding: 5px 0 5px 35px;
  background-color: #f9f9f9;
  border-bottom: 2px solid #eaeaea;
  margin: 25px 0 10px 0;
}

.mentions-politique h4 {
  font-size: 1rem;
  padding: 5px 0 5px 55px;
  background-color: #f1f1f1;
  margin: 25px 0 10px 0;
}

.mentions-politique p {
  padding: 7px 20px;
  text-align: justify;
}
.mentions-politique .no_justify {
  text-align: left;
}

.mentions-politique ul {
  margin: 20px 0 20px 50px;
}
.mentions-politique li {
  margin: 5px 0;
  list-style-type: circle;
}
.mentions-politique a {
  text-decoration: none;
  border-bottom: 1px dotted #797979;
  overflow-wrap: break-word;
}
.mentions-politique a:hover {
  border-bottom: 1px dotted #191818;
  color: #191818;
}
.titre-nb,
.sous_titre-nb,
.sous_s_titre-nb {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  text-align: center;
  margin-right: 7px;
  color: #fff;
}

.titre-nb {
  min-width: 45px;
  line-height: 45px;
}

.sous_titre-nb {
  background-color: #4c4c4c;
  min-width: 40px;
  line-height: 40px;
}
.sous_s_titre-nb {
  background-color: #9c9c9c;
  min-width: 25px;
  line-height: 25px;
}

@media screen and (max-width: 600px) {
  .mentions-politique {
    padding: 0 10px;
  }
  .mentions-politique h2 {
    font-size: 0.8rem;
    padding: 10px 0 10px 15px;
  }
  .mentions-politique h3 {
    font-size: 1rem;
    padding: 5px 0 5px 20px;
  }

  .mentions-politique h4 {
    font-size: 0.9rem;
    padding: 5px 0 5px 25px;
  }
  .mentions-politique p {
    padding: 7px 10px;
  }
  .titre-nb,
  .sous_titre-nb,
  .sous_s_titre-nb {
    font-weight: bold;
    background-color: transparent;
    color: #191818;
    min-width: auto;
    line-height: initial;
  }
}

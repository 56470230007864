.espace-client {
  .header.hikashop_header_title {
    position: relative;
    z-index: initial;
    height: initial;
    left: initial;
    width: 100%;
    box-shadow: none;
    border: 0;
    background: transparent;
    padding: initial;
  }

  .main__top {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-flow(row wrap);
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--gray-4);
  }

  #maincontent {
    max-width: var(--container);
    // margin: 8rem auto;
    margin: 4rem auto 8rem auto;
    padding: 0 1rem;
  }

  #login-form {
    @include flexbox;
    @include align-items(center);
    gap: 15px;
  }

  .article {
    // padding: 2rem 1rem;
  }

  .main__center {
    // display: grid;
    // grid-template-columns: 225px 1fr;
    // gap: 20px;
    // max-width: 1080px;
    // margin: 1rem auto 0 auto;

    aside {
      // border-right: 2px solid var(--gray-6);
    }

    .espace-client {
      // margin-left: 1rem;
    }
  }

  .espace-client__menu {
    // display: none;
  }
  &.espace-client--dashboard {
    .main__center {
      display: block;
    }
  }

  //   ------------------- titre
  .espace-client__title {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .subtitle {
    color: var(--gray-6);
  }
}

.espace-client--dashboard {
  //   ------------------- cards
  .cards {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-flow: dense;
    // @include align-items(flex-start);
    gap: 25px;
  }

  .card {
    // box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.08);
    border: 1px solid var(--color-1);
    text-align: center;
    transition: all 0.15s;
    border-radius: 7px;

    &:hover {
      // box-shadow: 0 5px 11px rgba($color: #000000, $alpha: 0.08);
    }

    a {
      display: block;
      padding: 2rem 1rem;
    }

    i {
      display: block;
      font-size: 3rem;
      margin: auto;
      color: var(--color-1);
    }

    .card__title {
      display: block;
      margin: 1rem 0;
      line-height: 1;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 500;
    }

    p {
      font-size: 1rem;
    }
  }

  .card--shop {
    background: var(--color-1);

    a,
    i,
    p {
      color: white;
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .espace-client .espace-client__title {
    font-size: 2rem;
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  .espace-client .espace-client__title {
    font-size: 1.8rem;
  }
}

@include breakpoint(980) {
}

@include breakpoint(780) {
  .espace-client .main__top {
    display: block;
    text-align: center;
  }
  .espace-client .espace-client__title {
    display: block;
    margin-bottom: 1rem;
  }
  .espace-client #login-form {
    @include justify-content(center);
  }
}

@include breakpoint(550) {
  .espace-client--dashboard .cards {
    gap: 20px;
  }

  .espace-client .espace-client__title {
    font-size: 1.5rem;
  }
  .espace-client--dashboard .card i {
    font-size: 2.4rem;
  }
  .espace-client--dashboard .card .card__title {
    font-size: 0.95rem;
  }
  .espace-client--dashboard .card p {
    font-size: 0.9rem;
  }
  .espace-client #login-form {
    @include flex-flow(column wrap);
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}

@charset 'utf-8';

@import "utils/functions";
@import "utils/mixins";
@import "utils/flexbox";
@import "utils/variables";
@import "utils/scrollbar";

@import "base/reset";
@import "base/responsive";
@import "base/typography";

@import "components/buttons";
@import "components/form";
@import "components/badge";
@import "components/pagination";
@import "components/modal";
@import "components/message";
@import "components/breadcrumb";
// @import "components/slide-all";
@import "components/loader";
// @import "components/parallax";
@import "components/social_share";

@import "hikashop/template";

@import "layout/header";
// @import "layout/layout";
@import "layout/footer";
@import "layout/grid";
@import "layout/header_top";
// @import "layout/navigation";

@import "pages/404";
@import "pages/sitemap";
@import "pages/rgpd";
@import "pages/home_1_hero";
@import "pages/home_2_a_propos";
@import "pages/home_3_prestations";
@import "pages/home_4_realisations";
@import "pages/tarifs";
@import "pages/extensions_cils";
@import "pages/prestations";
@import "pages/la_boutique";
// @import "pages/home_categories";
// @import "pages/home_creations";
// @import "pages/home_last_products";
// @import "pages/home_meilleures_ventes";
// @import "pages/home_promotions";
// @import "pages/blog";
@import "pages/contact_reservation";

@import "administration/dashboard";
@import "administration/header";
@import "administration/layout";
@import "administration/variables";
@import "administration/navigation";
@import "administration/navigation_top";
@import "administration/actualite";
@import "administration/profil";
@import "administration/hikamarket/layout";
@import "administration/hikamarket/caracteristiques";
@import "administration/hikamarket/categories";
@import "administration/hikamarket/clients";
@import "administration/hikamarket/commandes";
@import "administration/hikamarket/ma_page";
@import "administration/hikamarket/produits";
@import "administration/hikamarket/reductions";

@import "espace-client/login";
@import "espace-client/espace-client";
@import "espace-client/navigation";
@import "espace-client/dashboard";
@import "espace-client/commandes";
@import "espace-client/address";
@import "espace-client/wishlist";

// @import "vendors/swiper-bundle.scss";
@import "vendors/owl.carousel.css";
@import "vendors/owl.theme.default.css";
@import "vendors/plyr.css";

// ------ FONT SITE
@import url("https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap");

// ---------------------------------------------- FONTAWESOME
// @import "vendors/fontawesome/fontawesome";
// @import "vendors/fontawesome/solid";
// @import "vendors/fontawesome/regular";

#hikamarket_discount_listing {
  #filter_vendors {
    display: none;
  }

  .expand-filters {
    @include flexbox;
    // @include flex-flow(row wrap);
    gap: 25px;
  }
}

#hikamarket_discount_form {
  max-width: 550px;

  .hikamarket_discount_flatamount {
    @include flexbox;
    gap: 15px;
    #datadiscountdiscount_currency_id {
      max-width: 150px;
    }
  }
}

@include breakpoint(680) {
  #hikamarket_discount_listing .expand-filters {
    @include flex-flow(row wrap);
  }
}

.page-login {
  .header,
  .footer {
    // display: none;
  }

  .admin_login.page-login,
  .reset.page-login,
  .reset-confirm.page-login,
  .reset-complete.page-login {
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    overflow: auto;
    max-height: 100vh;
    width: 90%;
    margin: var(--padding-section) auto 8rem auto;
    background-color: white;
    border: 1px solid var(--gray-3);
    max-width: 600px;
    padding: 2rem 2rem;
    box-shadow: var(--shadow-1);
    border-radius: 10px;

    .btn {
      width: 100%;
    }
  }
}

.admin__menu {
  margin-top: 3rem;
  margin-left: 2rem;
  padding-bottom: 2rem;

  li {
    margin: 15px 0;
  }

  a {
    @include flexbox;
    @include align-items(center);
    padding: 10px 0 10px 10px;
    font-size: rem(18);
    border-radius: 10px 0 0 10px;
    text-align: left;

    &::before {
      @include icon();
      background-color: var(--color-1);
      width: 42px;
      height: 42px;
      line-height: 44px;
      text-align: center;
      font-size: 1.5rem;
      border-radius: 7px;
      margin-right: 10px;
      color: white;
    }
  }

  // ----------------- icon dashboard
  .item-152 a::before {
    content: "\f00a";
  }
  // ----------------- icon page
  .item-153 a::before {
    content: "\f4ff";
  }
  // ----------------- icon categories
  .item-215 a::before {
    content: "\f0e8";
  }
  // ----------------- icon caractéristiques
  .item-216 a::before {
    content: "\f085";
  }
  // ----------------- icon produits
  .item-155 a::before {
    content: "\f468";
  }
  // ----------------- icon commandes
  .item-154 a::before {
    content: "\f291";
  }
  // ----------------- icon clients
  .item-156 a::before {
    content: "\f0c0";
  }
  // ----------------- icon réductions
  .item-214 a::before {
    content: "\f295";
  }
  // ----------------- icon livraison
  .item-165 a::before {
    content: "\f0d1";
  }
  // ----------------- icon profil
  .item-161 a::before {
    content: "\f4fe";
  }

  img {
    display: none !important;
    width: 44px;
    height: 44px;
    padding: 10px;
    // image-rendering: -webkit-optimize-contrast;
    object-fit: contain;
    object-position: center;
    background-color: var(--admin-color-g2);
    border-radius: 7px;
    margin-right: 10px;
  }

  a:hover,
  li.active a {
    background-color: var(--color-1);
    color: white;

    img {
      background-color: var(--admin-color-light);
    }
  }
}

#admin__menu__btn {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  cursor: pointer;
  z-index: 100;
  outline: 0;
  background-color: transparent;
  border: 0;
  .menu__mobile__title {
    display: inline-flex;
    color: var(--color-dark);
    line-height: 0;
    font-size: rem(16);
    margin-top: rem(15);
  }
}

.menu__bar {
  position: relative;
  display: block;
  width: 40px;
  height: 2px;
  background-color: var(--background-dark);
  margin-top: 7px;
  border-radius: 2px;
  // transition: all 150ms;
}

#admin__menu__btn.menu__btn--active {
  // position: relative;
  .menu__bar--1 {
    opacity: 0;
  }
  .menu__bar--2 {
    transform: rotate(45deg);
  }
  .menu__bar--3 {
    transform: rotate(-45deg);
    top: -10px;
  }
}
// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .admin__menu {
    margin-top: 1rem;

    li {
      margin: 10px 0;
    }

    a {
      padding: 8px 0 8px 8px;
      font-size: rem(18);
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .admin__menu {
    margin-top: 1rem;

    li {
      margin: 8px 0;
    }

    a {
      padding: 7px 0 7px 7px;
      font-size: rem(16);
    }

    img {
      width: 36px;
      height: 36px;
    }
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  #admin__menu__btn {
    display: block;
  }
  //==================================================================== MENU

  .admin__menu {
    position: fixed;
    top: 80px;
    right: -300px;
    background-attachment: var(--background-light);
    margin: 0;
    width: 280px;
    height: 100vh;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    padding: rem(16) 0 0 rem(16);
    padding-left: rem(16);
    background-color: var(--background-light);
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
    transition: all 200ms;
    z-index: -1;
    border-top: 1px solid var(--admin-color-g2);

    li {
      color: var(--color-1);
    }

    a {
      font-size: rem(15);
      &::before {
        color: white;
      }
    }
  }

  // -------------------------- menu active
  .admin__menu--active {
    right: 0;
    z-index: 101;
    padding-bottom: 2rem;
  }
}

@include breakpoint(980) {
}

@include breakpoint(768) {
  // width 100% element
}

@include breakpoint(550) {
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}

.page-carte-cadeau {
  #hikashop_product_characteristics {
    &::before {
      content: "Choisissez votre montant";
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .hikashop_characteristic_line_40 {
    .controls {
      @include flexbox;
      @include flex-flow(row wrap);
      gap: 0.7rem;
    }
    td:first-of-type {
      display: none;
    }

    label {
      cursor: pointer;
      padding: 4px 1rem;
      margin: 0;
      font-size: rem(25);
      border: 1px solid var(--color-1);
      width: unset;
      height: unset;
      border-radius: unset;

      &.active {
        background-color: var(--color-1);
        color: white;
        border: 0;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .hika__product__stock-delivery {
    display: none;
  }

  #hikashop_product_description_main {
    display: none;
  }
}

.hikashop_paybox_end {
  margin: var(--padding-section) auto;
  max-width: 980px;
  text-align: center;

  .hikashop_paypal_end_message {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
}

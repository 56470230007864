.hika__hero {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--background-linear);
  }
  img {
    display: block;
    height: clamp(200px, 30vw, 250px);
    object-fit: cover;
  }
  .title {
    position: absolute;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    text-shadow: 2px 4px 8px rgba($color: #000000, $alpha: 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(1.2rem, 5vw, 5rem);
  }
}

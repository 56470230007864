// ======================================================== LIST

.hikamarket_user_name_title,
.hikamarket_user_email_title {
  text-align: left;
}

.hikamarket_user_login_title,
.hikamarket_user_login_value,
.hikamarket_user_username {
  display: none;
}

.hikamarket_order_status_value,
.hikamarket_order_date_value,
.hikamarket_order_total_value {
  text-align: center;
}

// ======================================================== DETAILS

#hikamarket_user_form {
  h2 {
    display: inline-block;
    margin-bottom: 2rem;
    text-align: left;
  }

  dt,
  label {
    margin-left: 0;
  }

  dd {
    font-weight: bold;
  }

  .hikam_options,
  #hikamarket_user_addresses_default {
    max-width: 550px;
  }

  // ----------------------- adresses

  .hk-row-fluid {
    // display: block;
  }

  .hikamarket_user_address {
    margin: 1rem 0;
  }

  .hikamarket_address_title {
    font-weight: bold;
  }

  #hikamarket_user_addresses_show {
    margin-bottom: 2rem;
  }
  // ----------------------- eo adresses

  tfoot {
    display: none;
  }
}

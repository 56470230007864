%form {
  display: block;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 100% !important;
  //----------------------------- STYLE
  font-family: var(--font-normal);
  background: transparent;
  box-shadow: none;
  color: var(--color-1);
  border: 1px solid var(--gray-4);
  border-radius: 4px;
  font-size: 1.1rem;
  // padding: 0 rem(50) 0 rem(16);
  padding-left: 15px;
  transition: box-shadow 200ms ease;

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 0.05);
    border: 1px solid var(--color-1);
  }

  &:focus {
    border: 1px solid var(--color-1);
  }
}

input {
  @extend %form;
  height: rem(60) !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-1) !important;
  font-size: 1rem;
  color: var(--color-dark);
  border: 1px solid var(--color-1);
}

input:-webkit-autofill::first-line {
  font-size: rem(20);
  color: var(--color-dark);
}

// -------------------------------------supprimer les cross

input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

// ------------------------------------- EO supprimer les cross

.form-group,
.control-group,
.hkform-group,
dl dd {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
}

.fox-column {
  min-height: initial !important;
}

fieldset {
  border: 0;
  font-size: rem(14);

  p {
    font-size: rem(15);
  }
}

legend {
  text-transform: initial;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
  background-color: transparent !important;
  color: var(--color-1);
}

// ---------------------------------------------------- radio & checkbox
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  background-color: transparent;
  display: inline-flex;
  vertical-align: middle;
  border: 1px solid var(--color-1);
  padding: 0 !important;
  box-shadow: none;
  width: 25px !important;
  height: 25px !important;
  border-radius: 2px;
  cursor: pointer;

  &:checked {
    &::after {
      content: "✔";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 25px;
      text-align: center;
      width: 100%;
      font-size: 1.1rem;
      background-color: var(--color-1);
      color: white;
    }
  }
  &:hover {
    // border: 0;
  }
  &:focus {
    // border: 0;
    // background-color: white;
  }
}

// ---------------------------------------------------- PASSWORD

// --- JS => form

.form__password {
  position: relative;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    z-index: 10;
    right: rem(15);
    bottom: 0;
    font-size: 1.4rem;
    cursor: pointer;
  }
}

label,
dl dt {
  position: relative;
  display: block;
  margin-left: rem(4);
  text-transform: uppercase;
  font-size: rem(14);
  color: var(--color-1);
  // color: $gray-8;
  // font-weight: 300;
}

.star {
  display: none;
}

// --------------------------- SELECT - supprimer arrow sur ie
select::-ms-expand {
  display: none;
}
select {
  @extend %form;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem(60);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMXJlbSIgd2lkdGg9IjFyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTEuNWExIDEgMCAwMS0uNzEtLjI5bC01LTVhMSAxIDAgMDExLjQyLTEuNDJMOCA5LjA5bDQuMjktNC4zYTEgMSAwIDExMS40MiAxLjQybC01IDVhMSAxIDAgMDEtLjcxLjI5eiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1.3rem;
  padding-right: 1.8rem;
  background-position: 98% center;
}

textarea {
  @extend %form;
  padding-top: 10px;
  max-width: 100%;
  max-height: 250px;
  line-height: 1.5 !important;
  resize: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-1);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-1);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-1);
}

@include breakpoint(1368) {
}

@include breakpoint(980) {
  legend {
    font-size: 1.2rem;
  }
}

@include breakpoint(550) {
  %form {
    font-size: rem(14) !important;
  }
  legend {
    // font-size: 1.8rem;
  }
  input,
  select {
    height: 50px !important;
  }
  label {
    margin-left: 0;
  }
  .form-group,
  .control-group,
  .hkform-group {
    label {
      font-size: 0.7rem;
      letter-spacing: 0;
    }
  }
  .form__password i {
    font-size: 1.1rem;
  }
}

.page-113 #maincontent {
  background-color: white;
  overflow: hidden;
}

.contenu-tarifs {
  padding: 0 15% 200px 15%;
}

.container-tarifs {
  position: relative;
  margin-bottom: 30px;
}

.container-tarifs::before {
  content: "";
  position: absolute;
  left: -25%;
  top: 15px;
  width: 25%;
  height: 10px;
  background-color: var(--gray-2);
}

.titre-tarifs,
.prix-tarifs {
  position: relative;
  float: left;
  line-height: 40px;
  height: 40px;
}

.titre-tarifs {
  width: 80%;
}

.titre-tarifs::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 118%;
  visibility: visible;
  height: 2px;
  border-top: 2px dashed var(--gray-2);
}

.link-tarifs {
  color: var(--color-1);
  margin-left: 7px;
  animation: anim-link 2s ease-out infinite alternate;
  -webkit-animation: anim-link 2s ease-out infinite alternate;
  -moz-animation: anim-link 2s ease-out infinite alternate;
  opacity: 1;
  &:hover {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

@keyframes anim-link {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes anim-link {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-moz-keyframes anim-link {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.titre-tarifs > span,
.prix-tarifs > span {
  position: relative;
  z-index: 1;
  background-color: white;
}

.titre-tarifs > span {
  font-size: 1.4rem;
  padding: 0 10px 0 15px;
}

.prix-tarifs {
  width: 20%;
  text-align: right;
}

.prix-tarifs > span {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--color-1);
  letter-spacing: 1px;
  padding-left: 10px;
}

.infos-tarifs {
  text-align: center;
}

.infos-tarifs ul {
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  font-style: italic;
  display: inline-block;
  margin-top: 30px;
  background: var(--gray-1);
  padding: 15px 30px;
  border-radius: 7px;
  border: 3px solid var(--gray-2);
}

.infos-tarifs li {
  margin: 7px 0;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1000px) {
  .contenu-tarifs {
    padding: 0 5% 100px 5%;
  }

  .titre-tarifs > span {
    font-size: 1.2rem;
  }

  .prix-tarifs > span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 800px) {
  .container-tarifs::before {
    top: 6px;
  }

  .titre-tarifs {
    width: 100%;
  }

  .titre-tarifs::after {
    content: none;
  }

  .titre-tarifs,
  .prix-tarifs {
    float: none;
    display: block;
    line-height: 25px;
    height: inherit;
    margin: 20px 0;
  }
  .prix-tarifs {
    position: relative;
    width: auto;
    border-bottom: 1px solid var(--color-1);
  }

  .prix-tarifs > span {
    position: absolute;
    right: -6%;
    top: -13px;
    background-color: var(--color-1);
    padding: 2px 7px;
    color: white;
  }
}

@media screen and (max-width: 500px) {
  .infos-tarifs {
    max-width: 100% !important;
  }
  .infos-tarifs ul {
    font-weight: normal;
    font-size: 1rem;
    font-style: normal;
  }
}

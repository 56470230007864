.admin--dashboard {
  #hikamarket_cpanel_title {
    display: none;
  }
}

#hikamarket_cpanel_main {
  .hikamarketcpanel > h2 {
    display: none;
  }

  .hikamarket_panel {
    border-radius: 10px;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.04);
  }

  // ---------------------------- menu left
  .hika_cpanel_side_bar {
    display: none;
  }

  // ---------------------------- content
  .hika_cpanel_main_data {
    width: 100%;
  }

  // ---------------------------- item
  .hikamarket_cpanel_statistics_top > div {
    margin-bottom: 1rem;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      .hikamarket_panel {
        min-height: 110px;
      }
    }
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(10) {
      .hikamarket_panel {
        min-height: 360px;
      }
    }
  }
  // ---------------------------- eo item

  // ---------------------------- title
  .hikamarket_panel_heading {
    font-size: rem(18);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-1);
  }
  // ---------------------------- value
  .hikamarket_panel_body {
    font-weight: bold;
    font-size: 2rem;
  }

  // ---------------------------- COMMANDES

  #google-visualization-errors-all-1 {
    display: none !important;
  }
  #hikamarket_dashboard_stat_orders_history {
    .hikabtn {
      margin-left: 0.5rem;
    }
  }

  // ---------------------------- table / total commandes & dernières commandes
  .hikamarket_stat_table {
    font-size: 1rem;
    font-weight: normal;
    thead tr {
      background-color: transparent !important;
    }
    th,
    td {
      text-align: center;
    }

    th {
      text-transform: uppercase;
      font-size: rem(14);
      font-weight: 500;
      text-align: left;
      padding: 10px 0;
      text-align: center;
    }
    tr:nth-child(odd) {
      background-color: var(--admin-color-g2);
    }
    td {
      padding: 10px 15px;
    }
  }
  #hikamarket_dashboard_stat_last_orders {
    th,
    td {
      text-align: center;
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  #hikamarket_cpanel_main .hikamarket_panel_heading {
    font-size: 1rem;
  }
  #hikamarket_cpanel_main .item__result {
    font-size: 1.7rem;
  }
  #hikamarket_cpanel_main .item:nth-child(1) .hikamarket_panel,
  #hikamarket_cpanel_main .item:nth-child(2) .hikamarket_panel,
  #hikamarket_cpanel_main .item:nth-child(3) .hikamarket_panel,
  #hikamarket_cpanel_main .item:nth-child(4) .hikamarket_panel {
    min-height: 100px;
  }

  #hikamarket_cpanel_main .hikamarket_stat_table th {
    font-size: 0.8rem;
  }
  #hikamarket_cpanel_main .hikamarket_stat_table td {
    font-size: 0.95rem;
  }
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
  #hikamarket_cpanel_main .hikamarket_panel_heading {
    font-size: 0.9rem;
  }
  #hikamarket_cpanel_main .item__result {
    font-size: 1.6rem;
  }
  #hikamarket_cpanel_main .item:nth-child(1) .hikamarket_panel,
  #hikamarket_cpanel_main .item:nth-child(2) .hikamarket_panel,
  #hikamarket_cpanel_main .item:nth-child(3) .hikamarket_panel,
  #hikamarket_cpanel_main .item:nth-child(4) .hikamarket_panel {
    min-height: 100px;
  }

  #hikamarket_cpanel_main .hikamarket_stat_table th {
    font-size: 0.75rem;
  }
  #hikamarket_cpanel_main .hikamarket_stat_table td {
    font-size: 0.9rem;
  }
}

@include breakpoint(1180) {
  #hikamarket_cpanel_main .item.hkc-md-3 {
    width: 50%;
  }
}

@include breakpoint(980) {
}

@include breakpoint(768) {
  // width 100% element
}

@include breakpoint(550) {
  #hikamarket_cpanel_main .item {
    margin: 0.5rem 0;
  }
  #hikamarket_cpanel_main .item.hkc-md-3 {
    width: 100%;
  }
  .hikamarket_panel {
    min-height: initial !important;
  }
  #hikamarket_cpanel_main .hikamarket_panel_heading {
    font-size: 0.8rem;
  }
  #hikamarket_cpanel_main .item__result {
    font-size: 1.3rem;
  }

  #hikamarket_cpanel_main .hikamarket_stat_table th {
    font-size: 0.7rem;
  }
  #hikamarket_cpanel_main .hikamarket_stat_table td {
    font-size: 0.8rem;
  }
}

@include breakpoint(440) {
  #hikamarket_cpanel_main .hikamarket_stat_table th {
    font-size: 0.6rem;
  }
  .espace-client--dashboard .cards {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@include breakpoint(360) {
}

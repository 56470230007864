.social__share {
  // background-color: var(--gray-6);
  border-radius: var(--radius);
  font-size: 1rem;
  // padding: 1em;
  @include flexbox;
  @include flex-flow(row wrap);
  @include align-items(center);
  .title {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  img {
    max-height: 30px;
  }

  ul {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 8px;
  }

  .btn--social {
    color: white;
    // padding: 1em !important;
    // height: 28px;
    width: auto;
    border-radius: 4px;
    // padding: 2px 6px;
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 4px;
    font-size: 0.85rem;
    svg {
      width: 24px;
      height: auto;
      // fill: white;
    }
  }

  .btn--facebook svg {
    fill: #1877f2;
  }

  .btn--twitter svg {
    fill: rgb(29, 155, 240);
  }

  .btn--pinterest i {
    fill: #1d9bf0;
  }

  .btn--email svg {
    fill: var(--color-1);
  }
}

@include breakpoint(780) {
  .social__share ul {
    @include justify-content(center);
  }
}

.menu-prestations-active {
  position: fixed;
  z-index: 100;
  width: 100%;
  text-align: center;
  background: var(--gray-2);
  top: -100px;
  left: 0;
  padding: 0;
  transition: all 300ms;
}

.menu-prestations-active.active-fixed {
  top: 0;
}

.menu-prestations-active div {
  padding-left: 120px;
}

.menu-prestations-active ul {
  display: inline-block;
  vertical-align: top;
}

.menu-prestations-active li {
  display: inline-block;
  vertical-align: top;
  margin: 0 15px;
  max-width: 130px;
}

.menu-prestations-active a {
  position: relative;
  z-index: 10;
  display: table-cell;
  vertical-align: middle;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 10px 0;
  width: auto;
  color: var(--color-1);
  height: 35px;
  margin: 0 15px;
  font-weight: bold;
  text-align: center;
  transition: all 300ms;
  &:hover {
    color: var(--color-dark);
  }
}

// ============================================================================================== prestations

#mariage-essai .plyr--video {
  width: 70%;
  margin: 0 auto 3rem auto;
}

/* ------------------------------- TITRE MARIAGE / COURS / SEANCE PHOTOS --------------------------------------------- */

.cols-pb .pb-title-slogan {
  top: 0;
  padding: 75px 0;
  margin-bottom: 75px;
  color: white;
  text-shadow: 0 0 40px var(--color-dark);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;

  a {
    display: block;
    margin-top: 2rem;
  }
}

#mariage-essai .pb-title-slogan {
  background-image: url(../../../images/maquillage-mariage-soiree-artistique/background-prestations-mariage.jpg);
}

#cour-maquillage-solo .pb-title-slogan {
  background-image: url(../../../images/maquillage-mariage-soiree-artistique/background-prestations-cours-maquillage.jpg);
}

#seance-photos-beaute .pb-title-slogan {
  background-image: url(../../../images/maquillage-mariage-soiree-artistique/background-prestations-seance-photos.jpg);
}

/* ------------------------------- LISTE PRESTATIONS --------------------------------------------- */

.cols-pb ul {
  text-align: left;
  font-style: italic;
  margin: 25px 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.cols-pb li {
  margin: 10px 0;
}

.cols-pb li svg,
.cols-pb li i {
  margin-right: 7px;
  color: var(--color-1);
  min-width: 25px;
}

.cols-pb li a {
  color: var(--color-1);
  border-bottom: 1px dotted var(--color-1);
}

/* ------------------------------- TARIFS --------------------------------------------- */

.prestations-tarifs {
  margin: 50px 0;
  text-align: center;
}

.prestations-tarifs div {
  margin: 15px 0;
}

.prestations-tarifs-titre,
.prestations-tarifs-supp {
  display: block;
}

.prestations-tarifs-titre {
  line-height: 75px;
  color: var(--color-1);
  font-size: 3.2rem;
  font-family: var(--font-titre);
}

.prestations-tarifs-titre::before,
.prestations-tarifs-titre::after {
  content: "";
  display: inline-block;
  width: 12%;
  height: 6px;
  background-color: var(--gray-2);
  vertical-align: middle;
}

.prestations-tarifs-supp::before,
.prestations-tarifs-supp::after {
  content: "";
  display: inline-block;
  width: 6%;
  height: 6px;
  background-color: var(--gray-2);
  vertical-align: middle;
}

.prestations-tarifs-titre::before {
  margin-right: 20px;
}
.prestations-tarifs-titre::after {
  margin-left: 20px;
}
.prestations-tarifs-supp::before {
  margin-right: 15px;
}
.prestations-tarifs-supp::after {
  margin-left: 15px;
}

.prestations-tarifs-euro {
  display: inline-block;
  background-color: var(--gray-2);
  color: var(--color-dark);
  font-weight: bold;
  font-size: 2.4rem;
  padding: 5px 15px;
  border-radius: 7px;
}

.prestations-tarifs-infos {
  font-size: 1.6rem;
  margin-left: 10px;
  font-weight: bold;
  color: var(--color-1);
  letter-spacing: 1px;
}

.prestations-tarifs-supp {
  line-height: 50px;
  margin: 10px 0;
  letter-spacing: 1px;
  font-weight: bold;
}

.prestations-tarifs-supp-titre {
  display: block;
  margin: 15px 0;
}

.prestations-tarifs-supp-titre_2 {
  display: inline-block;
}

.prestations-tarifs-supp-prix {
  background: #eaeaea;
  padding: 4px 7px;
  font-size: 1.6rem;
  color: #ffffff;
  text-shadow: 0 0 5px #000;
  font-weight: bold;
  border-radius: 4px;
}

.prestations-tarifs-supp-infos {
  font-size: 1.2rem;
  margin-left: 10px;
  letter-spacing: 1px;
}

.blog {
  background-color: white;
  padding-bottom: 300px;
}

.blog .leading {
  margin-bottom: 75px;
}

/* ------------------------------- TITRE --------------------------------------------- */

.prestations-titre {
  margin-bottom: 35px;
  font-size: 3.4rem;
  font-weight: normal;
  color: var(--color-1);
  font-family: var(--font-titre);
}

.cols-pb_2 .prestations-titre {
  text-align: right;
}

.cols-pb_1 .prestations-titre::before,
.cols-pb_2 .prestations-titre::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  height: 10px;
  background: var(--gray-2);
}
.cols-pb_1 .prestations-titre::before {
  margin-right: 20px;
}
.cols-pb_2 .prestations-titre::after {
  margin-left: 20px;
}

/* ------------------------------- COLUMN --------------------------------------------- */
.cols-pb {
  padding: 20px 0 100px 0;
}

.pb-link-txt {
  color: var(--color-1);
  border-bottom: 2px dashed var(--color-1);
  transition: all 300ms;
  &:hover {
    border-bottom: 2px solid var(--color-dark);
  }
}

.cols-2 .column-1 {
  width: 46%;
  float: left;
}
.cols-2 .column-2 {
  width: 46%;
  float: right;
  margin: 0;
}

.blog .column-2,
.blog .column-2 {
  padding-top: 1%;
  text-align: center;
}

.cols-pb_1 .column-1 {
  //image
  text-align: center;
}

.cols-pb_1 .column-2 {
  padding-right: 7%;
}

.cols-pb_2 .column-1 {
  //image
  float: right;
  text-align: center;
}
.cols-pb_2 .column-2 {
  float: left;
  padding-left: 7%;
}

.cols-pb .item-link {
  display: inline-block;
  z-index: 1;
  font-size: 4rem;
  margin-top: 40px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  &:hover::before {
    width: 110px;
    height: 48px;
  }
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1250px) {
  .blog {
    padding-bottom: 100px;
  }

  .blog .column-1,
  .blog .column-2 {
    width: 100%;
    float: none;
    padding: 0;
  }
  .cols-pb p,
  .cols-pb ul {
    padding: 7px 3%;
  }
  .prestations-titre {
    font-size: 3rem;
  }
  .cols-pb_1 .prestations-titre::before,
  .cols-pb_2 .prestations-titre::after {
    width: 15%;
  }
}

@media screen and (max-width: 600px) {
  .blog .leading {
    margin-bottom: 0;
  }

  .cols-pb .prestations-titre {
    text-align: center;
    font-size: 2.4rem;
    padding: 15px 3%;
    margin: 0 10px 35px 10px;
    background: var(--color-1);
    color: white;
  }

  .cols-pb_1 .prestations-titre::before,
  .cols-pb_2 .prestations-titre::after {
    content: none;
  }

  .prestations-tarifs-euro {
    font-size: 1.4rem;
  }
  .prestations-tarifs-infos {
    font-size: 1rem;
    letter-spacing: 0;
  }

  .prestations-tarifs-supp-titre_2 {
    padding: 0 3%;
  }

  .cols-pb .item-link {
    font-size: 2.5rem;
    margin-top: 0;
  }

  #mariage-essai .plyr--video {
    width: 95%;
  }
}

.pb-120 #centertop {
  background-color: white;
  overflow: hidden;
}

.menu-prestations {
  position: relative;
  top: 0;
  text-align: center;
  padding: 125px 0 50px 0;

  li {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    height: 175px;
    margin-bottom: 60px;
  }

  a {
    position: relative;
    z-index: 10;
    display: table-cell;
    width: 160px;
    height: 145px;
    vertical-align: middle;
    font-size: 0.9rem;
    text-transform: uppercase;
    padding-left: 27px;
    margin-top: 20px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 300ms;
  }
}

.menu-prestations,
.menu-prestations a:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.menu-prestations a::before {
  content: "";
  position: absolute;
  transition: all 300ms;
  z-index: -1;
  width: 200px;
  height: 200px;
  top: -125px;
  left: 0;
  background-size: cover;
}

.menu-prest_0::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/patrick-bordes-extensions-cils.jpg");
}
.menu-prest_1::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-beaute-naturelle.jpg");
}
.menu-prest_2::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-soiree.jpg");
}
.menu-prest_3::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-artistique.jpg");
}
.menu-prest_4::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-mariage.jpg");
}
.menu-prest_5::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-cours-maquillage.jpg");
}
.menu-prest_6::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-seance-photos.jpg");
}
.menu-prest_7::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-evjf.jpg");
}
.menu-prest_8::before {
  background-image: url("../../../images/maquillage-mariage-soiree-artistique/menu-prestations/background-homme.jpg");
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1500px) {
  .menu-prestations-active {
    li {
      margin: 0 8px;
    }
    a {
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 1250px) {
  .menu-prestations-active {
    @include hidden;
  }
}

@media screen and (max-width: 900px) {
}

.hikamarket_order {
  h2 {
    margin-bottom: 2rem;
  }

  dt,
  label {
    margin-left: 0;
  }
  dd {
    font-weight: bold;
  }
}

.hikamarket_order_addresses {
  // display: block;
  @include flex-flow(row wrap);

  .hkc-md-6 {
    padding: 2rem;
    background-color: white;
    margin-right: 1rem;
    margin-bottom: 2rem;
    width: 40%;
    min-width: 450px;
  }
  .hkdropdown {
    margin-left: 8px;
  }
}

// --------------------------------------- produits
#hikamarket_order_block_products {
  margin-bottom: 2rem;

  .hikamarket_order_item_name_value {
    div {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      gap: 15px;
    }
    .product__code {
      display: block;
      font-size: 0.8rem;
      font-weight: 500;
    }
    img {
      border-radius: 7px;
    }
  }
  .hikamarket_order_item_name_title {
    text-align: left;
  }
  .hikamarket_order_item_price_title,
  .hikamarket_order_item_price_value,
  .hikamarket_order_item_quantity_title,
  .hikamarket_order_item_quantity_value {
    text-align: center;
  }
  .hikamarket_order_item_total_price_title,
  .hikamarket_order_item_total_price_value {
    text-align: right;
  }
}
// --------------------------------------- eo produits

// --------------------------------------- historique

#hikamarket_order_history_listing {
  text-align: center;

  td a {
    display: none;
  }
}
// --------------------------------------- eo historique

@include breakpoint(880) {
  #hikamarket_order_block_products .hikamarket_order_item_name_value img {
    display: block;
    margin: auto;
  }
  #hikamarket_order_block_products .hikamarket_order_item_total_price_title,
  #hikamarket_order_block_products .hikamarket_order_item_total_price_value {
    text-align: center;
    &::before {
      content: "Total";
      display: block;
      font-weight: bold;
    }
  }
}

@include breakpoint(550) {
  .hikamarket_order_addresses .hkc-md-6 {
    min-width: 100%;
  }
}

//  ----------------- icons
.icon-upload::before {
  content: "ajouter une image";
  font-style: normal;
}

.icon-remove::before {
  content: "supprimer";
}

.icon-calendar::before {
  content: "choisir une date";
}

// ------------------------ btn modif frontend
a.btn.jmodedit::before {
  content: "modifier";
}
//  ----------------- mes actus

.admin--actu {
  .main__center {
    // max-width: 750px;
  }

  table.category {
    margin-top: 1rem;
    width: 100%;
    text-align: left;
    // ---------- header
    thead {
      border-bottom: 1px solid var(--gray-4);
      th {
        padding: 10px 0;
      }
    }
    #categorylist_header_title {
      padding-left: 15px;
    }
    #categorylist_header_edit,
    tr td:last-child {
      text-align: center;
    }
    .cat-list-row0 {
      background-color: var(--gray-3);
    }
    .cat-list-row0,
    .cat-list-row1 {
      &:hover .list-title a {
        color: var(--color-1);
      }
    }

    .list-title {
      width: 300px;
      padding-left: 15px;
      a {
        display: block;
      }
    }
    .list-date {
      width: 220px;
      text-align: center;
    }
    .list-edit {
      width: 150px;
    }

    td {
      padding: 20px 0;
    }
    // ---------- btn modifier
    .list-edit a {
      background-color: var(--color-1);
      color: white;
      padding: 2px 8px;
      font-size: rem(14);
      border: 1px solid transparent;
      &:hover {
        background-color: transparent;
        border: 1px solid var(--color-1);
        color: var(--color-1);
      }
    }
  }
  .btn-toolbar {
    margin-top: 2rem;
  }
}

// -------------------------------- Page Modifier article

.admin--actu {
  .article__body {
    max-width: 800px;
  }
  .article__header .icons .btn-group.pull-right {
    .btn.dropdown-toggle {
      display: none;
    }
    .dropdown-menu {
      margin-bottom: 1rem;
    }
  }
}

// -------------------------------- Modification article -> Admin et Front
#module-form,
#adminForm {
  // max-width: 800px;
  .btn-toolbar {
    @include flexbox;
  }
  .btn {
    font-size: rem(16);
    padding: 6px 12px;
    margin: 1rem 0.5rem 0.5rem 0;
  }

  #editor {
    margin-bottom: 1.5rem;
  }

  // .chzn-container {
  //   width: 100% !important;
  // }

  // .chzn-single {
  //   @extend %form;
  //   background: white !important ;
  //   height: 60px !important;
  //   line-height: initial !important;
  // }

  .btn[title="Versions"] {
    display: none;
  }
}

// ---------- module
#module-form {
  .page_title,
  #editor-xtd-buttons,
  .control-group,
  #moduleSlide {
    display: none;
  }
}

// ---------- article

.edit-icon a {
  @extend .btn;
}

#adminForm {
  #com-content-formTabs,
  #editor-xtd-buttons,
  #jform_tags_chzn,
  #jform_tags,
  #jform_tags-lbl,
  #jform_note,
  #jform_note-lbl,
  #jform_version_note,
  #jform_version_note-lbl,
  #jform_created_by_alias,
  #jform_created_by_alias-lbl,
  #jform_access_chzn,
  #jform_access-lbl,
  #jform_language_chzn,
  #jform_language-lbl,
  #jform_metadesc,
  #jform_metadesc-lbl,
  #jform_metakey,
  #jform_metakey-lbl,
  #jform_images_image_intro_caption,
  #jform_images_image_intro_caption-lbl,
  #jform_images_float_intro_chzn,
  #jform_images_float_intro-lbl,
  #jform_images_image_fulltext_caption,
  #jform_images_float_fulltext_chzn,
  #jform_images_float_fulltext-lbl,
  #jform_images_float_fulltext,
  #jform_images_image_fulltext_caption-lbl,
  #jform_urls_urla,
  #jform_urls_urlb,
  #jform_urls_urlc,
  #jform_urls_urla-lbl,
  #jform_urls_urlb-lbl,
  #jform_urls_urlc-lbl,
  #jform_urls_urlatext,
  #jform_urls_urlbtext,
  #jform_urls_urlctext,
  #jform_urls_urlatext-lbl,
  #jform_urls_urlbtext-lbl,
  #jform_urls_urlctext-lbl,
  #jform_catid,
  #jform_catid_chzn,
  #jform_catid-lbl,
  #jform_articletext_pagebreak,
  #jform_access,
  #jform_language {
    display: none !important;
  }
}

/* ------------------- BOUTON AJOUTER / MODIF ARTICLE / CATEGORIE ------------------- */

.links_btn_submit {
  margin-top: 1rem;
  input.btn,
  .btn {
    width: auto;
    margin: 0;
    height: initial;
  }
  input.btn {
    min-width: 250px;
    margin-right: 1.5rem;
  }
}

@include breakpoint(768) {
  .admin--actu #adminForm.form-inline {
    text-align: center;
  }
  .cat-list-row0,
  .cat-list-row1 {
    padding: 10px 0;
  }
  .admin--actu table.category td {
    display: block;
    text-align: center;
    width: 100% !important;
    padding: 5px;
    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }
}

.admin--product {
  #hikam_product_filters {
    display: none;
  }
}

#hikamarket_products_form {
  max-width: 980px;

  //   ------------------------------------------ wrapper

  .hk-row-fluid {
    display: block;
  }
  .hkc-md-4,
  .hkc-md-8 {
    width: 100%;
  }

  .hikamarket_section_container {
    margin-bottom: 2rem;
  }

  .hkm_quantity_input,
  .hikamarket_product_price,
  .field-calendar .input-append,
  .hikamarket_product_weight {
    @include inline-flex;
    @include align-items(center);
    // gap: 15px;
  }

  //   ------------------------------------------ eo wrapper
  .hikashop_uploader_image_empty,
  .hikashop_uploader_file_empty {
    padding: 3rem 0 1rem 0;
  }

  .hikashop_uploader_image_add,
  .hikashop_uploader_file_add {
    background-color: var(--gray-7);
    border-radius: 10px 10px 0 0;
    position: relative;
    display: block;
    top: 0;
    right: initial;
    padding: 5px;
    text-align: center;
    margin-bottom: 10px;
  }

  //   ------------------------------------------ header (produits / variantes)

  #hikamarket_product_edition_header {
    .hikam_tabs {
      @include flexbox;
      @include align-items(center);
      margin: 2rem 0 1rem 0;
      gap: 10px;
    }
    li {
    }
    a {
      padding: 6px 8px;
      border-radius: 7px;
      background-color: white;
      border: 1px solid var(--color-1);
      &:hover,
      &:active {
        background-color: var(--color-1);
        color: white;
      }
    }
    .active a {
      background-color: var(--color-1);
      color: white;
    }
    #hikamarket_product_variant_label {
      display: inline-block;
      margin-left: 4px;
      padding: 2px 6px;
      background-color: var(--color-1);
      color: white;
      text-align: center;
      border-radius: 7px;
    }
  }
  //   ------------------------------------------ eo header (produits / variantes)

  //   ------------------------------------------ image
  #hikamarket_product_image_main {
    margin-bottom: 1rem;
  }

  .hikamarket_main_image_div,
  .hikashop_main_image_div {
    padding: 5px;
    div.hikashop_uploader_image_add,
    div.hikashop_uploader_file_add {
    }
    .hika_j2 .btn-primary,
    .hikabtn-primary,
    .hika_j2 .btn-primary:hover,
    .hikabtn-primary:hover {
      background-color: var(--color-1) !important;
      border: 0 !important;
    }

    // Bouton ajouter photo via Url et liste images
    #hikamarket_product_image_addpopup,
    #hikamarket_product_image_urlpopup {
      display: none;
    }

    #hikamarket_product_image_uploadpopup {
      &::before {
        content: "Télécharger l'image";
        margin-right: 7px;
      }
    }

    #hikamarket_product_image_content {
      @include flexbox;
      @include align-items(center);
      @include flex-flow(row wrap);
      gap: 5px;
    }

    .hikashop_uploader_multiimage_content {
      img {
        width: 100px;
        height: 60px;
        object-fit: cover;
        object-position: center;
      }
    }

    .deleteImg {
      display: block !important;
      top: 2px !important;
      right: 2px !important;
      width: 25px;
      opacity: 1 !important;
      color: var(--color-1) !important;
      text-shadow: 0 2px 4px rgba($color: #ffffff, $alpha: 0.5);
      background-color: white;
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      font-size: 0.8rem;
    }
  }
  //   ------------------------------------------ eo image

  //   ------------------------------------------ qty

  .hkm_quantity_input {
    .hikabtn {
      height: 60px !important;
    }
  }

  //   ------------------------------------------ eo qty

  //   ------------------------------------------ dispo
  dd.hikamarket_product_salestart {
    @include flex-flow(row wrap);
    gap: 30px;

    // .btn {
    //   padding: 10px;
    //   width: 50px;
    // }
    .js-btn.btn {
      font-size: 0.8rem;
      padding: 4px 8px;
      margin-right: 7px;
    }
  }
  //   ------------------------------------------ eo dispo

  //   ------------------------------------------ spécifications

  #hikamarket_section_product_specifications {
    th:nth-of-type(2) {
      text-align: left;
    }
  }

  //   ------------------------------------------ eo spécifications

  //   ------------------------------------------ weight

  #dataproductproduct_weight_unit {
    max-width: 70px !important;
    margin-left: 4px;
  }

  //   ------------------------------------------ eo weight

  //   ------------------------------------------ file
  .hikamarket_product_file {
    padding: 0.5rem 1rem;
  }
  .hikamarket_product_file:nth-child(even) {
    background-color: var(--gray-2);
  }
  //   ------------------------------------------ eo file
}

//   ------------------------------------------ variantes
#hikamarket_product_variant_list {
  .hikam_table {
    tr {
      th:nth-of-type(3) {
        text-align: left;
      }
      // ---------------- prix / quantité
      th:nth-of-type(4),
      th:nth-of-type(5),
      td:nth-of-type(4),
      td:nth-of-type(5) {
        width: 150px;
        text-align: center;
        margin: auto;
      }
      // ---------------- prix / quantité
      th:nth-of-type(6),
      th:nth-of-type(7),
      td:nth-of-type(6),
      td:nth-of-type(7) {
        min-width: 100px;
        text-align: center;
        margin: auto;
        .publish {
          &::before {
            @include icon("\f058");
            font-size: 1.4rem;
            color: var(--success);
          }
        }
        .unpublish {
          &::before {
            @include icon("\f057");
            font-size: 1.4rem;
            color: var(--error);
          }
        }
      }
    }
  }
}
#hikamarket_product_variant_edition {
  .hikam_blocks {
    width: 100%;

    td {
      display: block;
    }
  }

  .hikamarket_product_price {
    margin-top: 1rem;
  }

  #hikamarket_product_variant_image_main {
    margin-bottom: 2rem;
  }
  dt.hikamarket_product_weight {
    display: block !important;
  }
}
.hikamarket_variant_toolbar {
  margin: 1rem 0;
}

//   ------------------------------------------ eo variantes

@include breakpoint(880) {
  .hikam_table .fa-pencil-alt {
    margin: 0 !important;
  }

  #hikamarket_product_variant_list .hikam_table tr td:nth-of-type(4)::before {
    content: "Prix";
  }
  #hikamarket_product_variant_list .hikam_table tr td:nth-of-type(5)::before {
    content: "Quantité";
  }
  #hikamarket_product_variant_list .hikam_table tr td:nth-of-type(6)::before {
    content: "Activé";
  }

  #hikamarket_product_variant_list .hikam_table tr td:nth-of-type(7)::before {
    content: "Défault";
  }
}

@include breakpoint(550) {
  #hikamarket_products_form .hkm_quantity_input .hikabtn {
    height: 50px !important;
  }
}

/*
************* COLORS
* ==================
*/
:root {
  --color-1: #cda33b;
  --color-2: #605399;
  --color-3: #ada1e6;
  --color-4: #e3def5;
  --color-5: #c769bd;
  --color-6: #e5c2ec;
  --color-7: #fdf1ff;
  --color-light: #ffffff;
  --color-dark: #111111;
  --gray-1: #f9f9f9;
  --gray-2: #f5f5f5;
  --gray-3: #f1f1f1;
  --gray-4: #eaeaea;
  --gray-5: #cbcdc8;
  --gray-6: #1d1d1d;
  --error: #ff4c4c;
  --warning: #ff7414;
  --success: #50c35f;
  --background-light: #ffffff;
  --background-light-2: #f9f8ff;
  --background-dark: #111111;
  --background-linear: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  --background-linear-2: linear-gradient(
    90deg,
    rgba(229, 194, 236, 1) 0%,
    rgba(227, 222, 245, 1) 100%
  );
  --shadow-text: 0 4px 8px rgba(56, 48, 91, 0.85);
  --gap: 8px;
  --padding-section: 6rem;
  --container: 1240px;
  --font-normal: "Times New Roman", serif;
  --font-titre: "Herr Von Muellerhoff", cursive;
  // --font-titre: "Pintanina Demo", Arial, sans-serif;
  --font-admin: "Times New Roman", serif;
  --font-awe: "Font Awesome 5 Free";
  --h1: 1.7rem;
  --h2: 3rem;
  --h3: 1.3rem;
  --h4: 1.1rem;
  --h5: 18px;
  --h6: 18px;
  --p: 1.4rem;
  // --card-bg: rgba(255, 255, 255, 0.7);
  // --bluegray: #b2bfcd;
  // --lightbluegray: #f6f9fc;
  // --toolbar-bg: white;
  // --success-border: var(--success);
  // --info-border: var(--info);
  // --warning-border: var(--warning);
  // --danger-border: var(--error);
  // --border: #ced4da;
  // --white: white;
  // --white-offset: #fefefe;
  // --focus: #39f;
  // --focus-shadow: 0 0 0 0.2rem #eaeaea;
  // --toggle-color: white;
  // --template-sidebar-bg: var(--template-bg-dark-80);
  // --template-sidebar-font-color: white;
  // --template-sidebar-link-color: white;
  // --template-bg-light: #f0f4fb;
  // --template-text-light: white;
  // --template-special-color: #132f53;
  // --template-link-color: #2a69b8;
  // --template-link-hover-color: #173a65;
  // --template-contrast: #2a69b8;
  // --template-bg-dark: hsl(var(--hue), 40%, 20%);
  // --template-bg-dark-3: hsl(var(--hue), 40%, 97%);
  // --template-bg-dark-5: hsl(var(--hue), 40%, 95%);
  // --template-bg-dark-7: hsl(var(--hue), 40%, 93%);
  // --template-bg-dark-10: hsl(var(--hue), 40%, 90%);
  // --template-bg-dark-15: hsl(var(--hue), 40%, 85%);
  // --template-bg-dark-20: hsl(var(--hue), 40%, 80%);
  // --template-bg-dark-30: hsl(var(--hue), 40%, 70%);
  // --template-bg-dark-40: hsl(var(--hue), 40%, 60%);
  // --template-bg-dark-50: hsl(var(--hue), 40%, 50%);
  // --template-bg-dark-60: hsl(var(--hue), 40%, 40%);
  // --template-bg-dark-65: hsl(var(--hue), 40%, 35%);
  // --template-bg-dark-70: hsl(var(--hue), 40%, 30%);
  // --template-bg-dark-75: hsl(var(--hue), 40%, 25%);
  // --template-bg-dark-80: hsl(var(--hue), 40%, 20%);
  // --template-bg-dark-90: hsl(var(--hue), 40%, 10%);
  // --gray-100: #f9fafb;
  // --gray-200: #eaedf0;
  // --gray-300: #dfe3e7;
  // --gray-400: #ced4da;
  // --gray-500: #adb5bd;
  // --gray-600: #6d757e;
  // --gray-700: #484f56;
  // --gray-800: #353b41;
  // --gray-900: #22262a;
  // --primary: #010156;
  // --secondary: #6d757e;
  // --success: #448344;
  // --info: #30638d;
  // --warning: #ad6200;
  // --danger: #a51f18;
  // --light: #f9fafb;
  // --dark: #353b41;
  // --primary-rgb: 1, 1, 86;
  // --secondary-rgb: 109, 117, 126;
  // --success-rgb: 68, 131, 68;
  // --info-rgb: 48, 99, 141;
  // --warning-rgb: 173, 98, 0;
  // --danger-rgb: 165, 31, 24;
  // --light-rgb: 249, 250, 251;
  // --dark-rgb: 53, 59, 65;
  // --white-rgb: 255, 255, 255;
  // --black-rgb: 0, 0, 0;
  // --body-color-rgb: 34, 38, 42;
  // --body-bg-rgb: 255, 255, 255;
  // --font-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  // --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  // --body-font-family: var(--cassiopeia-font-family-body, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  // --body-font-size: 1rem;
  // --body-font-weight: 400;
  // --body-line-height: 1.5;
  // --body-color: #22262a;
  // --body-bg: white;
}

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-1);
}

/*
************* FONT-SIZE
* ===================================
*/

$font-size-base: 16px !default;

/*
************* TIPS
* ===================================
*/

.nowrap {
  white-space: nowrap;
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  :root {
    --h1: 2.6rem;
    --h2: 2rem;
    --padding-section: 5.5rem;
  }
}

@include breakpoint(1440) {
  :root {
    --h1: 2.4rem;
    --padding-section: 5rem;
  }
}

@include breakpoint(1380) {
  :root {
    --h1: 2.2rem;
    --h2: 1.8rem;
    --padding-section: 4.8rem;
  }
}

@include breakpoint(1180) {
  :root {
    --h1: 2rem;
    --padding-section: 4.6rem;
  }
}

@include breakpoint(980) {
  :root {
    --padding-section: 4rem;
  }
}

@include breakpoint(780) {
  :root {
  }
}

@include breakpoint(550) {
  :root {
    --h1: 1.6rem;
    --h2: 1.6rem;
    --h3: 1.2rem;
    --padding-section: 3.5rem;
    --p: 16px;
  }
}

@include breakpoint(440) {
  :root {
  }
}

@include breakpoint(360) {
}

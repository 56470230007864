.hikashop_checkout_page {
  max-width: var(--container);
  margin: 4rem auto 8rem auto;
  padding: 0 2rem;
}

.hikashop_checkout_buttons {
  margin-top: 2rem;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  display: grid;
  grid-template-columns: 1fr 1fr;
  .buttons_right {
    text-align: right;
  }
}

// ================================================= SHIPPING / PAIEMENT

.hikashop_checkout_payment,
.hikashop_checkout_shipping {
  legend {
    // display: none;
  }
  tbody td {
    position: relative;
    display: block;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 7px;
  }
  label {
    margin: 0;
  }

  .hikashop_checkout_payment_name,
  .hikashop_checkout_shipping_name {
    @include flexbox;
    @include align-items(center);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    &::before {
      margin-right: 7px;
      font-size: 1.5rem;
    }
  }
  .hikashop_checkout_payment_name::before {
    @include icon("\f09d");
  }
  .hikashop_checkout_shipping_name::before {
  }

  .hikashop_checkout_payment_description,
  .hikashop_checkout_shipping_description {
    margin-top: 0.5rem;
    padding-right: 50px;
  }
  .hikashop_checkout_shipping_cost {
    font-weight: bold;
    font-size: 1.4rem;
  }
}

input.hikashop_checkout_shipping_radio,
input.hikashop_checkout_payment_radio {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent !important;
  border: 1px solid var(--gray-3);
  border-radius: 7px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &:checked::before {
    border-radius: 7px;
    border: 1px solid var(--color-1);
    // box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.08);
  }
  &:checked::after {
    @include icon("\f058");
    background-color: transparent;
    width: 30px !important;
    height: 30px;
    line-height: 30px !important;
    text-align: center;
    bottom: 10px !important;
    right: 10px !important;
    top: initial !important;
    left: initial !important;
    color: var(--color-1);
    font-size: 2rem;
  }
}

// ================================================= EO SHIPPING / PAIEMENT

@include breakpoint(650) {
  .hikashop_checkout_page {
    padding: 0 1rem;
  }
}

.hikashop_product_waitlist_page {
  max-width: 980px;
  margin: var(--padding-section) auto;

  .hikashop_product_waitlist_title {
    a {
      font-weight: bold;
    }
  }

  #adminForm {
    td {
      display: block;
      margin: 4px 0;
    }
  }
}

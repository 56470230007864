.espace-client__menu {
  // display: none;
  margin: 1rem 0 2rem 0;
  .item-125.parent,
  .nav-child {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 10px;
  }

  .nav-child li.active a {
    // color: var(--color-1);
    // border: 1px solid var(--color-1);
    background-color: var(--color-1);
    color: white;
    // border-bottom: 1px solid var(--color-1);
  }

  a:hover {
    color: var(--color-1);
  }

  a {
    font-size: rem(16);
    @include flexbox;
    @include align-items(center);
    padding: 8px 16px;
    text-transform: uppercase;
    // background-color: var(--gray-1);
    border: 1px solid var(--color-1);
    border-radius: 7px;
  }

  a::before {
    @include icon();
    display: inline-block;
    width: 30px;
    text-align: center;
    margin-right: 2px;
  }

  // ----------------- espace client
  .item-125.parent > a::before {
    content: "\f00a";
  }
  // ----------------- dashboard
  .item-125 > a::before {
    content: "\e066";
  }
  // ----------------- profil
  .item-128 a::before {
    content: "\f4fe";
  }
  // ----------------- boutique
  .item-157 a::before {
    content: "\f54e";
  }
  // ----------------- commandes
  .item-158 a::before {
    content: "\f291";
  }
  // ----------------- adresses
  .item-159 a::before {
    content: "\f3c5";
  }
  // ----------------- favoris
  .item-257 a::before {
    content: "\f0ca";
  }
}

@include breakpoint(1280) {
  .espace-client__menu a {
    font-size: rem(14);
    padding: 8px 8px;
  }
}

@include breakpoint(980) {
  .espace-client__menu a {
    font-size: rem(12);
    padding: 6px;
  }
}

@include breakpoint(768) {
  .espace-client__menu {
    // display: none;
  }
  .espace-client__menu .item-125.parent,
  .espace-client__menu .nav-child {
    display: block;
    li {
      margin: 0.5rem 0;
    }
    a {
      @include justify-content(center);
    }
  }
}

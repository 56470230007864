.quelques-realisations {
  text-align: center;
  background-color: white;
  padding-bottom: 200px;
}

.quelques-realisations .cols-3 {
  max-width: 80%;
  margin: 0 auto;
  @include flexbox;
  @include flex-flow(row wrap);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.5rem;
}
.quelques-realisations .column-2 {
  margin-top: 8%;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1000px) {
  .quelques-realisations {
    padding-bottom: 100px;
    .column-3 {
      @include hidden;
    }
  }
}

.page-boutique {
  .archive.page-boutique {
    display: none;
  }

  .hikashop_products_listing {
    // margin: var(--padding-section) 0 10rem 0;
    // margin-bottom: 10rem;
  }
  #hikashop_module_194 {
    padding-bottom: 10rem;
  }
}

.hikashop_cart {
  position: relative;
  z-index: 110;
  text-align: right;
  //   color: var(--color-1);
  // ========================================================================================= Icon panier
  .hikashop_small_cart_checkout_link {
    &::before {
      @include icon(" \f07a");
      margin-right: 7px;
    }
  }

  .hikashop_product_code_cart {
    display: none;
  }

  // loader.scss

  // substitutions lang -> X_ITEMS_FOR_X et X_ITEM_FOR_X
  // <span class="cart__qty">%s</span><span class="cart__title">Panier</span>

  .cart__qty {
    position: absolute;
    z-index: 1;
    left: 14px;
    bottom: -5px;
    background-color: var(--color-1);
    color: white;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50%;
    font-size: 0.75rem !important;
    box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.3);
  }

  // ========================================================================================= Contenu

  // --------------------------------- wrapper
  .hikashop_cart_dropdown_content {
    position: absolute;
    z-index: 100;
    right: 0;
    width: 300px !important;
    padding: 0.5rem 0 0 0 !important;
    border: 0 !important;
    box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.16);
    border-radius: 8px;
    background-color: var(--color-light);

    table.hikashop_cart {
      table-layout: fixed;
      // ------------------------------------------------------------------------- header
      thead {
        display: none;
      }
      // ------------------------------------------------------------------------- body
      tbody {
        tr {
          position: relative;
          display: grid;
          grid-template-columns: 140px 140px;
          padding: 8px 10px;

          &::after {
            content: "";
            position: absolute;
            left: 10px;
            bottom: 0;
            display: block;
            width: calc(100% - 20px);
            border-bottom: 1px solid var(--gray-2);
          }
        }

        td {
          line-height: 25px;
        }
        // --------------------------------- image
        .hikashop_cart_module_product_image {
        }

        // --------------------------------- titre
        .hikashop_cart_module_product_name_value {
          grid-column: 1 / span2;
          text-align: left;
          line-height: 1.5;

          a {
            text-transform: lowercase;
            font-size: 0.9rem;
          }
        }
        // --------------------------------------------- Quantité
        //  ----> configuration hikashop -> affichage -> gauche/droite
        .hikashop_cart_module_product_quantity_value {
          text-align: left;
          color: var(--color-1);
          .hikashop_product_quantity_change_div_leftright {
            @include flexbox;
            @include align-items(center);
            color: var(--color-1);
            &::before {
              content: "Quantité";
              font-size: 0.8rem;
              margin-right: 7px;
              font-weight: 500;
            }
          }
          .hikashop_product_quantity_change_div_default,
          .hikashop_product_quantity_field_change,
          .hikashop_cart_product_quantity_refresh,
          .add-on {
            display: none;
          }
          .hikashop_product_quantity_field {
            font-size: 0.8rem;
            max-width: initial;
            font-weight: 500;
            width: 30px !important;
            background-color: transparent;
            text-align: left;
            pointer-events: none;
            border: 0;
            height: initial !important;
            color: var(--color-1);
            padding: 0;
          }
        }
        // --------------------------------- prix
        .hikashop_cart_module_product_price_value {
          text-align: right;
          display: none;
        }
      }

      // ------------------------------------------------------------------------- footer / total

      tfoot tr {
        @include flexbox;
        padding: 0 10px;
        gap: 10px;
        margin: 1.1rem 0;
      }

      .hikashop_cart_module_product_total_title {
        text-align: right;
      }
      .hikashop_cart_module_product_total_value {
        text-align: left;
      }
      .hikashop_product_price {
        font-size: initial;
      }
    }
    // --------------------------------- bouton terminer commande
    .hikashop_cart_proceed_to_checkout {
      // width: calc(100% - 20px);
      width: 100%;
      margin: auto;
      background-color: var(--color-1);
      color: white;
      border: 0;
      padding: 12px 0;
      border-radius: 0;
    }
  }
}
// ========================================================================================= RESPONSIVE

@include breakpoint(650) {
  .hikashop_cart .hikashop_small_cart_checkout_link::before {
    transform: scale(0.8);
  }
}

@include breakpoint(550) {
  .hikashop_cart .cart__qty {
    font-size: 0.7rem !important;
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  .hikashop_cart .hikashop_cart_dropdown_content {
    position: fixed !important;
    top: 85px;
    width: 90vw !important;
    left: 2% !important;
    right: initial !important;
    margin: 0 !important;
  }
}

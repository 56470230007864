.admin--profil,
.espace-client--profil {
  .admin--profil,
  .espace-client--profil {
    max-width: 500px;
    #jform_username-lbl,
    #jform_username,
    .optional {
      display: none;
    }
  }
}

.espace-client--favoris {
  #hikashop_share_cart {
    display: none;
  }
  #datacart_share {
    display: none;
  }
  label[for="cart_share"] {
    display: none;
  }
  // ================================== TABLE
  #hikashop_cart_product_listing {
    thead {
      border-bottom: 1px solid var(--gray-5);
      th {
        padding: 6px;
      }
    }
    tbody {
      td {
        padding: 6px;
      }
      tr:nth-child(even) {
        background-color: var(--gray-3);
      }
      #hikashop_product_quantity_field_1_area {
        @include justify-content(center);
      }
      td[data-title="Quantité"] {
        text-align: center;
        color: var(--color-1);
      }
    }
    .hika_show_cart_total_text_2 {
      display: none;
    }
    .hikashop_cart_product_code {
      display: none;
    }
    tfoot {
      border-top: 1px solid var(--gray-5);
      background-color: var(--gray-2);
      td {
        padding: 8px;
      }
    }
  }
}

// ==================================== listing des favoris

#hikashop_cart_listing {
  thead tr {
    background-color: var(--gray-5);
    th:first-child {
      text-align: left;
    }
  }
  tbody tr:nth-child(even) {
    background-color: var(--gray-3);
  }
  tbody td:first-child {
    text-align: left;
    color: var(--color-1);
    i {
      margin-right: 7px;
    }
  }
  tbody td {
    text-align: center;
  }
  th,
  td {
    padding: 0.5em;
    //   text-align: center;
  }
}
.hikashop_cart_current_value {
  i {
    color: var(--color-1);
  }
}

@include breakpoint(800) {
  #hikashop_cart_listing {
    font-size: 0.8rem;
  }
}

@include breakpoint(800) {
  #hikashop_cart_listing {
    thead {
      display: none;
    }
    tr {
      border: 1px solid var(--gray-3);
    }
    tr,
    td {
      display: block;
      text-align: center !important;
    }
  }
}

.checkout__payment {
  margin: var(--padding-section) auto;
  max-width: 980px;
  text-align: center;
  padding: 0 1rem;

  .payement__success {
    svg {
      fill: var(--success);
    }
  }
  .payement__refuse {
    svg {
      fill: var(--error);
    }
  }

  svg {
    display: block;
    margin: 0 auto 1rem auto;
  }
  .payement__title {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
}

.header__top {
  @include flexbox;
  @include align-items(center);
  @include flex-flow(row wrap);
  @include justify-content(space-between);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5em 0;
  margin-bottom: 1rem;

  // voir breadcrumb.scss

  // .header__compte,
  // .hikashop_cart_module {
  //   color: var(--color-1);
  //   font-size: 1.4rem;
  //   text-align: right;
  // }

  .header__top--right {
    @include flexbox;
    @include justify-content(flex-end);
    gap: 1em;
    color: var(--color-1);
    font-size: 1.3rem;
  }
}

@include breakpoint(440) {
  .header__top {
    display: block;
  }

  .header__top .header__top--right {
    @include justify-content(flex-start);
  }
}

.badge {
  position: fixed;
  z-index: 120;
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;

  img {
    width: 70px;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 70px;
  }

  &::before {
    content: "";
    display: block;
    top: -120%;
    right: -120%;
    position: absolute;
    z-index: -1;
    width: 240px;
    transform: rotate(45deg);
    height: 230px;
    background: white;
    border-bottom: 3px solid var(--color-1);
    box-shadow: 10px 20px 41px rgba(0, 0, 0, 0.26);
  }
}

@media screen and (max-width: 1000px) {
  .badge {
    width: 60px;
    height: 60px;
    &::before {
      content: none;
    }
  }
}

@media screen and (max-width: 440px) {
  .badge {
    &::before {
      content: none;
    }
  }
}

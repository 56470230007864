/*
************* BTN PRIMARY
* ===================================
*/

.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: 3px solid var(--color-dark);
  color: var(--color-dark);
  letter-spacing: 1px;
  height: initial;
  background: transparent;
  // @include shadow-1;
  font-size: 1.3rem;
  border-radius: 4px;
  line-height: 1;
  font-weight: 500;
  // text-transform: lowercase;
  padding: 7px 12px;
  // transition: background 200ms, color 200ms;
  // margin-top: 2rem;

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 0.8);
    background-color: var(--color-1);
    color: white;
    // transition: all 100ms;
    border: 3px solid transparent;
  }

  &:active,
  &:focus {
    background-color: var(--color-1);
    color: white;
  }
}

.btn-h {
  display: block;
  position: relative;
  z-index: 10;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: var(--gray-3);
  color: white;
  margin: 0 auto;
  font-size: 1.5rem;
}

.pb-link {
  position: relative;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 40px;
  padding: 4px 0;
  margin: 7px 0;
}

.pb-link::before,
.pb-link::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -10px;
  left: 50%;
  margin-left: -75px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
}
.pb-link::before {
  width: 150px;
  height: 69px;
  background-image: url("../../../images/maquilleur-professionnel/background-link-argent.png");
}
.pb-link:hover::before {
  background-size: 0 0;
}
.pb-link::after {
  width: 0;
  height: 0;
  background-image: url("../../../images/maquilleur-professionnel/background-link-or.png");
}

.pb-link:hover::after {
  width: 150px;
  height: 69px;
}

input.btn {
  height: initial !important;
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  touch-action: manipulation;
  font-family: var(--font-normal);
}

/*
************* BTN SECONDARY
* ===================================
*/

.btn--outlined {
  background-color: transparent;
  color: var(--color-1);
  border: 1px solid var(--color-1);

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 1);
    // transition: all 200ms;
    // color: white;
    // border: 3px solid transparent;
  }

  &:active,
  &:focus,
  &.active {
    // background-color: var(--color-1);
    // color: white;
  }
}

/*
************* BTN CUSTOM
* ===================================
*/

.btn--custom {
  color: var(--color-1);
  background-color: transparent;
  padding: 0 !important;
  font-size: 1.1rem;
  font-weight: 300;
  &::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: var(--color-1);
    transition: width 0.2s;
  }
  &:hover {
    border-color: transparent;
  }
  &:hover::after {
    width: 100%;
  }
}

.btn--small {
  padding: 4px 8px;
  font-size: 0.8rem;
}

.btn--submit {
  width: 100%;
}

.btn--success,
.btn--success:hover,
.btn--success:active,
.btn--success:focus {
  background-color: var(--success);
  border-color: var(--success);
}

.btn--danger,
.btn--danger:hover,
.btn--danger:active,
.btn--danger:focus,
.hika_j2 .btn-danger:hover,
.hikabtn-danger:hover,
.hika_j2 .btn-danger,
.hikabtn-danger {
  background-color: var(--error);
  border-color: var(--error);
}

/*
************* DISABLED
* ===================================
*/
.btn[disabled] {
  cursor: default;
  opacity: 0.1;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-1);
    color: var(--color-light);
  }
}

/*
************* BTN TOP
* ===================================
*/

.btn__top {
  position: fixed;
  right: 1rem;
  bottom: 3rem;
  transform: translateY(150px);
  z-index: 1000;
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  .btn__top__link {
    // background-color: $gray-6;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-1);
    color: white;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.55);
    // border: 3px solid var(--color-light);
    transition: all 0.2s;

    i {
      font-size: 2rem;
    }
  }
}
.btn__top--active {
  transform: translateY(0);
  opacity: 1;
}

@include breakpoint(550) {
  .btn {
    padding: 8px 12px;
    font-size: rem(16);
  }
  .btn__top .btn__top__link {
    width: 50px;
    height: 50px;
  }
  .btn__top .btn__top__link i {
    font-size: 1.5rem;
  }
}

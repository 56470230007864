// ------------------------------------------------------------------------------ STEP

.hika__step {
  padding-top: 2rem;
  text-align: center;
  margin-bottom: 3rem;
  ul {
    @include flexbox;
    @include justify-content(center);
    gap: 60px;
  }

  li {
    // font-family: var(--font-titre);
    font-size: 1.1rem;
  }

  .step__icon {
    position: relative;
    // z-index: 100;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin: 0 auto 10px auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid var(--color-1);
    color: white;
    background-color: var(--color-1);
    font-size: 2.2rem;
  }

  .step__icon--current::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid white;
  }

  .step__icon--current,
  .step__icon--finished {
    background-color: var(--color-1);
    color: white;
    border-color: var(--color-1);
    z-index: 10;
  }

  //   ---------------- line
  .step--next .step__icon::before,
  .step--finished .step__icon::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 10px;
    display: none;
  }

  .step__icon--current {
    z-index: 10;
  }

  .step--next .step__icon::before {
    right: 100%;
    background: var(--gray-2);
  }
  .step--finished .step__icon::before {
    left: 100%;
    background-color: var(--color-1);
  }

  //   ---------------- eo line

  .step--finished a {
    color: var(--color-1);
  }
}

@include breakpoint(1440) {
  .hika__step {
    padding-top: 1rem;
  }
  .hika__step li {
    // font-size: 1.7rem;
  }
  .hika__step .step__icon {
    font-size: 1.75rem;
    margin-bottom: 4px;
    width: 70px;
    height: 70px;
  }
}

@include breakpoint(1180) {
  .checkout__logo {
    position: relative;
    text-align: center;
    left: 0;
    top: 0;
    margin: 2rem 0 1rem 0;
  }
}

@include breakpoint(980) {
  .hika__step ul {
    gap: 30px;
  }
  .hika__step li {
    font-size: 1rem;
  }
  .hika__step .step__icon {
    font-size: 1.2rem;
    margin-bottom: 4px;
    width: 50px;
    height: 50px;
  }
}

@include breakpoint(550) {
  .hika__step {
    margin-bottom: 1.5rem;
  }
  .hika__step ul {
    gap: 15px;
  }
  .hika__step li {
    font-size: 0.8rem;
  }
  .hika__step .step__icon {
    font-size: 1rem;
    margin-bottom: 4px;
    width: 40px;
    height: 40px;
  }
}

// ------------------------------------------------------------------------------ EO STEP

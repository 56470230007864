/*
PATRICK
*/
.pb-114 #maincenter,
.pb-250 #maincenter {
  background-color: white;
  overflow: hidden;
}

/* ----------------- RESERVATION ----------------- */

#presta_id {
  display: none;
}

#fox-container-m184 {
  .fox-item-recaptcha,
  .fox-item-submit {
    margin: 0;
  }
}

/* ----------------- CONTACT ----------------- */

#fox-container-m99 {
  .fox-item-recaptcha {
    margin-top: -170px !important;
  }
  .fox-item-submit {
    // margin-top: -90px !important;
  }
}

/* -------------------------------------------------------------------------------------------- */

.fox-container {
  padding: 0 1rem 200px 1rem;
}

.fox-form,
.fox-form p {
  text-align: center !important;
}

.fox-item-html {
  margin-bottom: 35px !important;
}

.fox-item {
  .controls {
    text-align: center !important;
    float: none !important;
  }

  input,
  textarea {
    border: 2px solid var(--gray-2) !important;
    padding: 4px 5px 4px 15px !important;
    background-color: white !important;
    border-radius: 5px !important;
  }

  input {
    line-height: 60px !important;
    height: 60px !important;
  }

  textarea {
    height: 420px !important;
  }

  input:focus,
  textarea:focus {
    box-shadow: none !important;
    color: var(--color-1) !important;
    outline: none !important;
    border: 2px solidvar(--color-1) !important;
  }
}

.fox-item-recaptcha .controls,
.fox-item-submit .controls {
  width: 48% !important;
}

/* --------- CALENDRIER --------- */

.fox-item-calendar .fox-item-calendar-input {
  background-color: var(--gray-1) !important;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active .xdsoft_calendar td:hover,
.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_timepicker.active
  .xdsoft_time_box
  > div
  > div:hover,
.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_label
  > .xdsoft_select
  > div
  > .xdsoft_option:hover,
.xdsoft_datetimepicker
  .xdsoft_timepicker.active
  .xdsoft_time_box
  > div
  > div:hover {
  background: var(--color-1) !important;
}

.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_calendar
  td.xdsoft_default,
.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_calendar
  td.xdsoft_current,
.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_timepicker.active
  .xdsoft_time_box
  > div
  > div.xdsoft_current,
.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_label
  > .xdsoft_select
  > div
  > .xdsoft_option.xdsoft_current,
.xdsoft_datetimepicker
  .xdsoft_timepicker.active
  .xdsoft_time_box
  > div
  > div.xdsoft_current {
  position: relative;
  z-index: 1;
  color: var(--color-dark) !important;
  background: white !important;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active td {
  text-align: center !important;
}

.xdsoft_datetimepicker
  .xdsoft_datepicker.active
  .xdsoft_calendar
  td.xdsoft_today {
  color: var(--color-1);
  font-weight: bold !important;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active .xdsoft_calendar td > div {
  padding: 0 !important;
}

/* --------- LISTE DEROULANTE --------- */

.fox-item-dropdown {
  .chzn-single {
    height: 50px !important;
    line-height: 50px !important;
    font-weight: bold;
    font-size: 1.2rem;
    box-shadow: none !important;
    background: white !important;
    border: 2px solid var(--gray-2);
    span {
      margin-right: 40px !important;
    }
    div {
      width: 40px !important;
      b {
        position: relative;
        background: none !important;
        background-size: 0 0 !important;
      }
      b::after {
        @include icon("\f107");
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-size: 2rem;
      }
    }
  }
  .chzn-search {
    display: none !important;
  }
  .chzn-drop {
    border-top: 2px solid var(--gray-2);
  }
  .chzn-with-drop b::after {
    content: "\f106" !important;
  }
  .chzn-results li {
    position: relative;
    line-height: 40px !important;
    font-size: 1.2rem;
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 50%;
      margin-left: -15%;
      width: 30%;
      height: 2px;
      background-color: var(--gray-2);
    }
    &.highlighted {
      background: none !important;
      transition: all 300ms !important;
      color: var(--color-1) !important;
    }
  }
  .search-choice-close {
    display: none !important;
  }
}

#fox-container-m109 .infos-tarifs ul {
  text-align: center;
  font-weight: normal;
  font-size: 1.2rem;
  max-width: 480px;
  font-style: normal;
  display: inline-block;
  margin-top: 0;
  background: var(--gray-1);
  padding: 0;
  border-radius: 7px;
  border: 3px solid var(--gray-2);
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1250px) {
  .fox-column {
    float: none !important;
    width: 100% !important;
    margin-left: 0 !important;
  }

  .fox-item input,
  .fox-item-dropdown-select-single,
  .fox-item-text-area textarea {
    width: 90% !important;
  }

  .infos-tarifs {
    max-width: 90%;
    margin: 0 auto;
    ul {
      padding: 0 10px;
    }
  }
  .fox-item-recaptcha .controls,
  .fox-item-submit .controls {
    width: 100% !important;
  }

  /* ------------ contact ------------ */
  #fox-container-m111 {
    .fox-item-recaptcha,
    .fox-item-submit {
      margin: 25px 0 !important;
    }
  }
}

.hikam_toolbar {
  background-color: transparent !important;
  border: none !important;
  .hikabtn {
    .fa {
      color: white;
    }
    &:hover .fa {
      color: var(--color-1);
    }
  }
}

.hika_toolbar_btn,
.hikam_toolbar_btn {
  @include inline-flex;
  @include flex-flow(row wrap);
  padding: 1rem 0;
  gap: 10px;

  // margin-left: 7px;

  //   text-align: center;
  .hikabtn {
    @include inline-flex;
    @include align-items(center);
    border: 1px solid var(--color-1);
    color: white;
    background-color: var(--color-1);
    margin-right: 7px;
    margin-bottom: 7px;
    // color: var(--color-1);
    &:hover {
      background-color: transparent;
      border: 1px solid var(--color-1);
      color: var(--color-1);
    }
  }
  .btnIcon.hk-icon,
  i {
    font-size: 1rem;
    margin-right: 6px;
    float: none !important;
  }

  .hikam_toolbar_right a.hikabtn[href*="#apply"] {
    display: none;
  }
}

@include breakpoint(768) {
  .hika_toolbar_btn .hikabtn {
    display: block !important;
    margin: 8px 0;
    white-space: initial;
  }
}

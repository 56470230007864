.hikashop_checkout_login {
  text-align: center;
}

#hikashop_checkout_login_form,
#hikashop_checkout_registration,
.hikashop_checkout_billing_address_block,
.hikashop_checkout_shipping_address_block {
  padding: 1rem;
  border-radius: 7px;
  border: 1px solid var(--gray-4);
}
#hikashop_checkout_login_form,
.hikashop_checkout_billing_address_block {
  margin-right: 1rem;
}
#hikashop_checkout_registration,
.hikashop_checkout_shipping_address_blockq {
  margin-left: 1rem;
}

// ========================================================== LOGIN

.hikashop_checkout_login {
  .hk-container-fluid {
    @include justify-content(center);
    margin: 2rem 0;
  }

  .hkc-lg-4,
  .hkc-lg-8 {
    width: 50% !important;
  }

  .hikashop_login_forgot_username_line,
  .hikashop_registration_required_info_line {
    display: none;
  }

  .hikabtn {
    width: 100%;
  }
}

// ========================================================== EO LOGIN

// ========================================================== ADRESSE

.hikashop_checkout_address {
  .hikashop_checkout_checkout_address_block {
    max-width: 600px;
    margin: auto;
  }

  .hika_address_field {
    position: relative;
  }

  legend {
    position: relative;
    top: 1.5rem;
  }

  .hika_address_element {
    margin-bottom: 1rem;
  }

  .hikashop_address_required_info_line {
    text-align: center;
  }

  //   ----------------------- boutons modifier / supprimer
  .hika_edit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    @include flexbox;

    gap: 10px;
    a {
      text-align: center;
      @include flexbox;
      @include align-items(center);
      padding: 2px 8px;
      color: white;
      font-size: 0.8rem;
      i {
        margin-right: 7px;
      }
      span {
        display: block;
      }
    }
    a:first-of-type {
      background-color: var(--color-1);
    }
    a:last-of-type {
      background-color: transparent;
      border: 1px solid var(--color-1);
      color: var(--color-1);
    }
  }
  //   ----------------------- EO boutons modifier / supprimer

  //   ----------------------- infos adresse

  .hika_address_display {
    padding: 10px 0 0 15px;
    text-transform: uppercase;
    font-size: 0.9rem;
    line-height: 1.7;
  }
  //   ----------------------- EO infos adresse
}

// ========================================================== EO ADRESSE

@include breakpoint(980) {
  .hikashop_checkout_login .hkc-lg-4,
  .hikashop_checkout_login .hkc-lg-8,
  #hikashop_checkout_address_2_1 .hkc-sm-6 {
    width: 100% !important;
    margin-bottom: 1rem;
  }
  #hikashop_checkout_registration,
  .hikashop_checkout_shipping_address_blockq {
    margin-left: 0;
  }
  #hikashop_checkout_login_form,
  .hikashop_checkout_billing_address_block {
    margin: 0;
  }
}

@include breakpoint(550) {
  #hikashop_checkout_login_form,
  #hikashop_checkout_registration,
  .hikashop_checkout_billing_address_block,
  .hikashop_checkout_shipping_address_block {
    padding: 1rem 1rem 2rem 1rem;
  }
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: var(--gray-4);
}

::-webkit-scrollbar-thumb {
  background: var(--color-1);
  border: 4px solid var(--gray-4);
  border-radius: 3px;
}

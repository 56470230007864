#modules2mod1 {
  position: relative;
}

.a-slide,
.bg-slide {
  height: 85vh;
  min-height: 600px;
}

.a-slide .owl-stage-outer {
  overflow: visible !important;
}

.a-slide {
  position: relative;
  z-index: 110;
  margin-top: 40px;
  width: 100%;
  overflow: hidden;
}

.diplome {
  position: absolute;
  top: 80px;
  left: -129px;
  z-index: 10;
  text-align: center;
  font-weight: bold;
  background-color: var(--gray-600);
  color: white;
  font-size: 1rem;
  padding: 8px 115px;
  box-shadow: 0 0 25px rgba($color: #000000, $alpha: 1);
  transform: rotate(-45deg);
  border-top: 4px solid var(--color-1);
  border-bottom: 4px solid var(--color-1);
}

.a-slide-titre {
  position: relative;
  z-index: 10;
  text-align: right;
  right: 50px;
  padding-top: 53px;
}
.a-slide-titre h1 {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(transparent, transparent),
    url("../../../images/maquilleur-professionnel/background-gold.jpg");
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  font-size: 5rem;
  text-transform: uppercase;
}
.a-slide-titre span {
  color: white;
  position: relative;
  padding: 25px 0;
  display: block;
  font-size: 1.8rem;
  letter-spacing: 4px;
  font-weight: bold;
  &::before {
    content: "";
    position: absolute;
    width: 303px;
    right: -50px;
    top: 10px;
    height: 3px;
    background-color: white;
  }
}

.a-slide-link {
  position: absolute;
  z-index: 10;
  text-align: right;
  right: 50px;
  bottom: 25%;
}

.a-slide-link .pb-link {
  color: white;
  font-family: var(--font-titre);
  margin: 0 50px;
  font-size: 3.5rem;
  text-shadow: 0 0 15px var(--color-dark);
}

.a-slide-slogan {
  position: absolute;
  z-index: 10;
  right: 50px;
  bottom: 5px;
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.owl-carousel-active .owl-item.active {
  width: auto !important;
}

.a-slide .owl-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 10s infinite alternate-reverse scale;
  -webkit-animation: 10s infinite alternate-reverse scale;
  -moz-animation: 10s infinite alternate-reverse scale;
  -o-animation: 10s infinite alternate-reverse scale;
}

.bg-slide {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
}

.bg-slide_1 {
  background-image: url("../../../images/maquilleur-professionnel/maquilleur-professionnel-patrick-bordes-slide-4.jpg");
}
.bg-slide_2 {
  background-image: url("../../../images/maquilleur-professionnel/maquilleur-professionnel-patrick-bordes-slide-5.jpg");
}
.bg-slide_3 {
  background-image: url("../../../images/maquilleur-professionnel/maquilleur-professionnel-patrick-bordes-slide-2.jpg");
}
.bg-slide_4 {
  background-image: url("../../../images/maquilleur-professionnel/maquilleur-professionnel-patrick-bordes-slide-3.jpg");
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.06);
  }
}

.acc-video {
  position: fixed;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.acc-video .plyr--video {
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.acc-video-active {
  z-index: 99999;
}

.acc-close {
  position: absolute;
  cursor: pointer;
  color: white;
  top: 25px;
  right: 200px;
  font-size: 3rem;
}

.acc-video-active {
  opacity: 1;
  width: 100%;
  top: 0%;
  left: 0%;
  height: 100%;
}

.a-slide__video {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 100;
  @include flexbox;
  @include align-items(center);
  @include flex-flow(row wrap);
  gap: 1rem;
}

.btn-video {
  position: relative;
  cursor: pointer;
  z-index: 100;
  border-radius: 7px;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  background: white;
  color: var(--color-dark);
  animation: anim-video 3s ease-in-out infinite;
  -webkit-animation: anim-video 3s ease-in-out infinite;
  -moz-animation: anim-video 3s ease-in-out infinite;
}

@keyframes anim-video {
  0% {
    background: white;
    color: var(--color-dark);
  }
  50% {
    background: var(--color-dark);
    color: white;
  }
  100% {
    background: white;
    color: var(--color-dark);
  }
}
@-webkit-keyframes anim-video {
  0% {
    background: white;
    color: var(--color-dark);
  }
  50% {
    background: var(--color-dark);
    color: white;
  }
  100% {
    background: white;
    color: var(--color-dark);
  }
}
@-moz-keyframes anim-video {
  0% {
    background: white;
    color: var(--color-dark);
  }
  50% {
    background: var(--color-dark);
    color: white;
  }
  100% {
    background: white;
    color: var(--color-dark);
  }
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1500px) {
  .a-slide,
  .bg-slide {
    height: 78vh;
    min-height: 400px;
  }
  .a-slide-titre h1 {
    font-size: 4rem;
  }
  .a-slide__video {
    bottom: 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .a-slide {
    margin-top: 0;
  }

  .a-slide-titre {
    right: 10px;
    padding-top: 0;
    h1 {
      font-size: 2rem;
    }
    span {
      font-size: 1.3rem;
    }
  }

  .a-slide-slogan {
    width: 100%;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
  }
  .link-a-propos {
    display: none;
  }

  .a-slide-link {
    display: none;
  }

  .a-slide__video {
    // top: 100%;
    left: 0;
    width: 100%;
    @include justify-content(center);
  }

  // .btn-video {
  //   position: relative;
  //   display: block;
  //   bottom: initial !important;
  //   left: 0;
  //   text-align: center;
  //   width: 100%;
  //   padding: 0.5rem 0;
  //   margin: 10px 0;
  // }
}

@media screen and (max-width: 750px) {
  .a-slide-link {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .a-slide,
  .bg-slide {
    height: 70vh;
    min-height: 250px;
  }
  .a-slide-slogan {
    display: none;
  }
  .diplome {
    bottom: 15px;
    left: 0;
    top: inherit;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px 0;
    transform: rotate(0);
    border-top: 3px solid #cda33b;
    border-bottom: 3px solid #cda33b;
  }
  .a-slide__video {
    bottom: 100px;
    gap: 8px;
  }
  .btn-video {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
}

#hikashop_checkout_form {
}

.hikashop_checkout_page_step1,
.hikashop_checkout_page_step3 {
  .hkc-md-6:first-of-type {
    width: 75%;
    padding-right: 30px;
  }
  .hkc-md-6:last-of-type {
    width: 25%;
  }
}

// ======================================= cart

// ------------- cart left

#hikashop_checkout_cart_1_0 {
  padding-right: 25px;
  #hikashop_cart_product_image_title {
    text-align: left;
  }

  #hikashop_cart_product_name_title {
    opacity: 0;
  }

  thead {
    tr {
      border-bottom: 1px solid var(--gray-3);
    }
  }

  tr {
    position: relative;
  }

  td {
    padding: 8px 0;
  }

  .row1 {
    border-top: 1px solid var(--gray-3);
  }

  .hikashop_cart_product_image_value {
    width: 150px;
    img {
      image-rendering: -webkit-optimize-contrast;
      max-height: 100px;
      max-width: 100px;
    }
  }

  .hikashop_cart_product_name {
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 0.85rem;
  }

  .hikashop_cart_product_custom_item_fields {
    p {
      font-size: 0.8rem;
    }
  }

  .edit_cart_product {
    display: none !important;
  }

  .hikashop_cart_product_price_value,
  .hikashop_cart_product_total_value {
    text-align: center;
    width: 120px;
  }

  .hikashop_product_quantity_change_div_leftright {
    // hikashop->components->quantity

    // @include flexbox;
    // @include align-items(center);
    @include justify-content(center);
    // .add-on {
    //   display: block;
    //   margin: 0 8px;
    // }

    // a {
    //   position: relative;
    //   display: block;
    //   text-align: center;
    //   background-color: var(--color-1);
    //   width: 24px;
    //   height: 24px;
    //   line-height: 24px;
    //   color: white;
    //   padding: 0;
    //   border-radius: 50%;
    // }

    // input {
    //   text-align: center;
    //   background-color: white;
    //   border: 0;
    //   width: 34px !important;
    //   height: 34px !important;
    //   max-width: initial;
    //   padding: 0;
    //   border-radius: 50%;
    // }
  }

  .hikashop_cart_product_quantity_refresh {
    display: none;
  }

  .hikashop_cart_product_quantity_delete {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-5);
  }

  .hikashop_cart_subtotal_title,
  .hikashop_cart_subtotal_value,
  .hikashop_cart_total_title,
  .hikashop_cart_total_value,
  .hikashop_cart_tax_title,
  .hikashop_cart_tax_value {
    display: none;
  }
}

// ------------- cart right

#hikashop_checkout_cart_1_2,
#hikashop_checkout_cart_3_2 {
  box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.08);
  padding: 1rem;

  &::before {
    content: "Montant de mon panier";
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    padding: 0 0 0.5rem 0;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid var(--gray-4);
  }

  .table {
    width: 90%;
    margin: auto;
  }

  thead {
    display: none;
  }

  .hikashop_cart_product_name_value,
  .hikashop_cart_product_price_value,
  .hikashop_cart_tax_value,
  .hikashop_cart_tax_title,
  .hikashop_cart_product_quantity_value,
  .hikashop_cart_product_total_value {
    display: none;
  }

  .hikashop_cart_subtotal_value,
  .hikashop_cart_shipping_value,
  .hikashop_cart_tax_value,
  .hikashop_cart_total_value,
  .hikashop_cart_coupon_value {
    text-align: right;
    padding: 4px 0;
  }

  .hikashop_cart_total_title,
  .hikashop_cart_total_value {
    font-weight: 500;
    font-size: 1rem;
    padding: 8px 0;
    border-top: 1px solid var(--gray-4);
    text-transform: uppercase;
  }
}

// ====================================================== COUPON

#hikashop_checkout_coupon_1_3 {
  margin: 2rem 0;
  .btn {
    width: 100%;
    margin-top: 1rem;
  }
  a[title="Supprimer le coupon"] {
    display: inline-flex;
    // margin-top: 1rem;
    align-items: center;
    padding: 4px;
    font-size: 0.9rem;
    border: 1px solid var(--color-1);
    color: var(--color-1);
    &::after {
      content: "Supprimer le coupon";
      margin-left: 4px;
    }
  }
}

// ====================================================== EO COUPON

@include breakpoint(1080) {
  #hikashop_checkout_cart_1_0 {
    padding-right: 0;
  }

  .hikashop_checkout_page_step1,
  .hikashop_checkout_page_step3 {
    .hk-row-fluid {
      display: block;
    }
    .hkc-md-6 {
      width: 100% !important;
      padding-right: 0 !important;
    }
  }
}

@include breakpoint(768) {
  #hikashop_checkout_cart_1_0 {
    thead {
      display: none;
    }
    tbody,
    tr {
      display: block;
    }
    .row0,
    .row1 {
      border: 1px solid var(--gray-4);
      margin-bottom: 1rem;
    }
    td {
      display: block;
      text-align: center;
      width: 100% !important;
    }
    .hikashop_cart_product_quantity_delete {
      right: 10px !important;
      top: 5px !important;
      transform: initial !important;
    }
  }
}

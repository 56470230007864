.hk-card {
  background-color: var(--background-light);
  border: 0;

  .hk-card-header {
    background-color: var(--color-1);
    border: 0;
    .hk-row-fluid {
      @include justify-content(space-between);
    }
    .hkc-sm-6:last-child {
      text-align: right;
    }
    i {
      margin-right: 7px;
    }
  }

  .hk-card-body {
    .hk-row-fluid {
      @include align-items(center);
    }
  }

  // ==================================== ORDRE CATEGORIES ET PRODUITS

  .hk-input-group-prepend,
  .hk-input-group-append {
    display: block;
    a {
      display: block;
    }
  }
  .hkm_order_value {
    text-align: center;
  }

  // ==================================== EO ORDRE CATEGORIES ET PRODUITS
}

@include breakpoint(767) {
  .hk-card {
    text-align: center;
    .hk-card-header {
      .hk-row-fluid {
      }
      .hkc-sm-6:last-child {
        text-align: center;
      }
      i {
      }
    }

    .hk-card-body {
      .hk-row-fluid {
        gap: 10px;
      }
    }
  }
}

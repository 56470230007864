.vex-content {
  width: 90% !important;
  max-width: 700px !important;
  overflow: hidden !important;
  box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.26);
  border-radius: 10px !important;

  iframe {
    width: 100% !important;
  }
}

.vex-overlay {
  background-color: rgba($color: #000000, $alpha: 0.55) !important;
}

.page-administration {
  --admin-header-width: 340px;
  --admin-padding: 32px;
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .page-administration {
    --admin-header-width: 275px;
  }
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .page-administration {
    --admin-header-width: 250px;
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  .page-administration {
    --admin-header-width: 0px;
    --admin-padding: 16px;
  }
}

@include breakpoint(980) {
}

@include breakpoint(780) {
}

@include breakpoint(550) {
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}

.espace-client--commandes {
  // ------------------------------------ SEARCH
  .hikashop_search_block {
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 15px;
  }
  // ------------------------------------ EO SEARCH

  // ------------------------------------ TRIER
  .hikashop_order_sort {
    display: grid;
    grid-template-columns: 250px 1fr 1fr;
    @include align-items(center);
    gap: 15px;
    margin: 1.5rem 0;
    br {
      display: none;
    }
  }
  // ------------------------------------ EO TRIER
}

// ------------------------------------ LISTE
.hk-card-order {
  // ---------------- prix
  .hika_cpanel_price {
    text-align: right;
  }
  // ---------------- arrow voir plus
  .hika_order_more {
    text-align: right;
    i {
      margin: 0;
    }
  }
  // ---------------- details
  .hika_order_product {
    @include flexbox;
    @include align-items(center);
    display: grid;
    grid-template-columns: 80px 1fr 1fr;

    .hika_order_product_price {
      text-align: right;
    }
  }
}

.hika_no_orders {
  border: 1px solid var(--color-1);
  background-color: transparent;
}

// ------------------------------------ EO LISTE

// ------------------------------------ DETAILS

#hikashop_order_main {
  h1 {
    color: var(--color-1);
  }

  .hikashop_order_main_table {
    width: 100%;
  }

  //   ---------- date et société
  .hikashop_order_right_part,
  .hikashop_order_left_part {
    margin-bottom: 1rem;
  }
  //   ---------- adresse de facturation et livraison
  #htmlfieldset_billing {
    margin-right: 2rem;
  }
  #htmlfieldset_billing,
  #htmlfieldset_shipping {
    padding: 0 1rem 1rem 1rem;
    background-color: white;

    legend {
      padding: 0 8px;
      margin-bottom: 2rem;
      position: relative;
      top: 24px;
    }
  }

  //   ---------- liste des produits
  #htmlfieldset_products {
    margin: 1.5rem 0 2rem 0;
    thead {
      display: none;
    }
    .row1 {
      background-color: white;
    }

    .hikashop_order_item_image_value {
      width: 80px;
    }
    .hikashop_order_item_price_value {
      text-align: center;
      width: 200px;
    }
    .hikashop_order_item_quantity_value {
      text-align: center;
      width: 80px;
    }
    .hikashop_order_item_total_value,
    .hikashop_order_subtotal_value,
    .hikashop_order_shipping_value,
    .hikashop_order_tax_value,
    .hikashop_order_total_value {
      text-align: right;
      width: 200px;
    }
  }
}

// ------------------------------------ EO DETAILS

@include breakpoint(980) {
  .espace-client--commandes .hikashop_order_sort {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(766) {
  .hk-card-order {
    .hk-row-fluid {
      gap: 8px;
    }

    .hk-card-body,
    .hika_order_more {
      text-align: center;
    }

    .hkdropdown-menu-right {
      left: 50%;
      right: initial;
      transform: translateX(-50%);
      width: 320px;

      a {
        text-align: center;
        white-space: normal;
      }
    }
  }
  .hikashop_order_main_table {
    text-align: center !important;
    table {
      width: 100%;
    }
    td {
      display: block;
      text-align: center !important;
      width: 100% !important;
      padding: 3px 0;
      border: 0 !important;
    }
    #htmlfieldset_billing {
      margin-right: 0 !important;
    }

    label {
      background-color: var(--gray-2);
    }
    .hikashop_order_subtotal_title,
    .hikashop_order_shipping_title,
    .hikashop_order_total_title {
      text-align: left;
    }
  }
}

@include breakpoint(550) {
  .espace-client--commandes .hikashop_search_block {
    grid-template-columns: 1fr;
    gap: 5px;
  }
}

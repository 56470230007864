.header {
  position: fixed;
  z-index: 100;
  width: 270px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  border-right: 1px solid var(--gray-2);
  border-left: 1px solid var(--gray-2);
  background-color: var(--gray-1);
  text-align: center;
}

.page-101 .header {
  background-color: var(--gray-1);
}

.header.header-active {
  left: -276px;
}

.header__menu {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.header.menu-active {
  left: 81px;
  z-index: 1000;
}

/* ----- Logo ----- */

.header img {
  margin: 10px 0 15px;
}

/* ----- Menu ----- */

.menu__main a {
  position: relative;
  display: block;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 2px;
  line-height: 40px;
  padding: 4px 0;
  margin: 7px 0;
  font-weight: bold;
  background-image: url("../../../images/maquilleur-professionnel/background-menu-argent.png");
  background-size: 110px auto;
  background-repeat: no-repeat;
  background-position: center center;
  &:hover {
    background-size: 0 0;
  }
}

.menu__main .active a {
  background-image: url("../../../images//maquilleur-professionnel/background-menu-or.png");
  &:hover {
    background-size: 110px auto;
  }
}

.menu__main a::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 80px;
  width: 0;
  height: 0;
  background-image: url("../../../images//maquilleur-professionnel/background-menu-or.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
}

.menu__main a:hover::before,
.menu__main .active a::before {
  width: 110px;
  height: 48px;
  // .transition;
}

.item-251,
.item-252 {
  a::after {
    position: absolute;
    height: 16px;
    line-height: 16px;
    left: 50%;
    bottom: -0.1rem;
    transform: translateX(-50%);
    content: "nouveau";
    background-color: var(--color-1);
    color: white;
    font-weight: bold;
    letter-spacing: 0;
    font-size: 0.6rem;
    padding: 0 4px;
  }
}

/* ----- Tél / facebook ----- */

.h-tel-fb {
  // position: absolute;
  // width: 80%;
  // bottom: 10px;
  // left: 10%;
  margin-top: 2rem;
  text-align: center;
}

.h-tel-fb span {
  display: block;
  text-transform: uppercase;
}
.h-tel-fb svg,
.h-tel-fb i {
  margin-right: 7px;
  font-size: 1.8rem;
}
.tel-pb {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-1);
  border-top: 1px solid var(--gray-5);
  border-bottom: 1px solid var(--gray-5);
  padding: 5px 0;
}

.fb-pb {
  margin: 10px 0;
}

/* ----- Mobile ----- */
.header-mobile {
  position: fixed;
  z-index: 99999;
  width: 80px;
  height: 100%;
  top: 0;
  left: -85px;
  background-color: white;
  border-right: 1px solid var(--gray-2);
}

.header-active .header-mobile {
  left: 0;
}

.pb-rot {
  transform: rotate(-90deg);
  display: block;
  position: relative;
  text-transform: uppercase;
  margin-top: 185px;
  letter-spacing: -1px;
  text-shadow: 0 0 1px var(--gray-5);
  font-weight: bold;
  left: 0px;
  font-size: 1.6rem;
  white-space: nowrap;
}
.menu-mob {
  display: block;
  cursor: pointer;
  margin-top: 45px;
  background-color: var(--color-dark);
  color: white;
  &:hover {
    color: var(--color-1);
  }
}

.menu-mob svg,
.menu-mob i {
  font-size: 2.5rem;
  height: 70px;
  line-height: 70px;
}

.arrow-menu {
  position: relative;
}

.arrow-menu,
.menu-active .arrow-menu,
.header-active.menu-active .arrow-menu {
  opacity: 0;
}

.header-active .arrow-menu {
  opacity: 1;
}

.arrow-menu-img {
  position: absolute;
  left: 4px;
  top: 15px;
  width: 50px;
  height: 49px;
  background-image: url("../../../images/maquilleur-professionnel/arrow-menu.png");
  background-size: cover;
  transform: rotate(34deg);
}
.arrow-menu-title {
  position: absolute;
  width: 100%;
  left: 44px;
  top: 50px;
  font-family: var(--font-titre);
  font-size: 2.5rem;
}

/* ======================================================================  RESPONSIVE ============================= */
@media screen and (max-width: 1700px) {
  .headermod1 img {
    width: 150px;
  }

  .menu__main a {
    padding: 2px 0;
  }
}

@media screen and (max-width: 1500px) {
  .header {
    left: -276px;
  }
  .header-mobile {
    left: 0;
  }
  .pb-119 .header-mobile {
    background-color: var(--gray-2);
  }

  .header-active .header-mobile {
    background-color: white;
  }
  .header-active .arrow-menu,
  .arrow-menu {
    opacity: 1;
  }

  .menu__main a {
    margin: 0;
  }
}

@media screen and (max-width: 1000px) {
  .header-mobile {
    width: 100%;
    height: 70px;
    left: 0;
  }
  .pb-119 .header-mobile {
    background: white;
    border-bottom: 3px solidvar(--gray-2);
  }
  .pb-119 .header-active .header-mobile {
    border-bottom: 0;
  }
  .header-mobile,
  .page-101 .header-active .header-mobile {
    background: var(--gray-2);
    text-align: left;
  }
  .pb-rot {
    transform: rotate(0);
    margin: 0 25px;
    display: inline-block;
    line-height: 70px;
    vertical-align: top;
  }
  .menu-mob {
    display: inline-block;
    margin-top: 0;
    width: 70px;
    text-align: center;
  }
  .pb-119 .header-mobile {
    text-align: left;
  }

  .arrow-menu {
    display: none;
  }

  .arrow-menu-title {
    left: 72px;
    top: -47px;
  }
  .arrow-menu-img {
    left: 20px;
    top: -27px;
    transform: rotate(-50deg);
  }

  .header.menu-active {
    width: 100%;
    left: 0;
    top: 70px;
    padding-top: 4rem;
    max-height: auto;
  }
  .header__menu {
    max-height: 70vh;
  }
  .header img {
    display: none;
  }
  .menu__main a {
    &::before {
      left: 50%;
      margin-left: -55px;
    }
  }
  .h-tel-fb {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .arrow-menu {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .pb-rot {
    font-size: 1.3rem;
    margin: 0 10px;
  }
  .menu-mob {
    width: 50px;
  }
  .menu-mob svg,
  .menu-mob i {
    font-size: 2rem;
  }
}

@media screen and (max-width: 370px) {
  .menu-mob {
    width: 50px;
  }
  .menu-mob svg,
  .menu-mob i {
    font-size: 2rem;
  }
}

@media screen and (max-width: 320px) {
  .pb-rot {
    font-size: 1rem;
  }
}

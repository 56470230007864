html {
  // scroll-behavior: smooth; / Remplacé par le smooth JS compatible IOS
  // height: 4000px;
}

body {
  word-break: break-word;
  text-rendering: optimizeLegibility;
  // background-color: var(--gray-2);
  background-color: white;

  &.page-101 {
    background-color: white;
  }
}

body,
.content,
.header,
.header-mobile,
.f-mentions {
  transition: all 300ms;
}

.body-active,
.body-active.page-101 {
  background-color: var(--gray-2);
}

// .overlay {
//   position: fixed;
//   top: 0%;
//   left: 0;
//   z-index: -1;
//   width: 100%;
//   height: 100%;
//   transition: background 0.15s;
// }

// .body--active {
//   .overlay {
//     z-index: 1000;
//     background-color: rgba($color: #000000, $alpha: 0.45);
//   }
// }

#wrapper {
  position: relative;
  // width: 100%;
  margin: auto;
  min-height: 100vh;
  max-width: 2000px;
  overflow: hidden;
}

.main {
  --full-width: 80px;
  // margin-top: 100px;
  // padding: 0 var(--full-width);
}

#maincontent {
  background-color: white;
}

.content {
  padding: 0 40px 0 320px;
}

.contenu {
  padding: var(--padding-section) 0;
  overflow: hidden;
  background-color: #fff;
}

.body-active .content {
  padding: 0 40px 0 120px;
  background-color: white;
}

.full-width {
  width: calc(100% + var(--full-width) * 2);
  margin-left: calc(-1 * var(--full-width));
}

// .container {
//   position: relative;
//   max-width: rem(1180);
//   margin: auto;
//   padding: var(--padding-section) 1rem;
// }

.section {
  padding: var(--padding-section) 0;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1500px) {
  body .content {
    padding: 0 50px 0 130px;
  }
}

@media screen and (max-width: 1000px) {
  body .content,
  .body-active .content {
    padding: 100px 3%;
  }
  .btn__top {
    right: 3%;
  }
}

@media screen and (max-width: 850px) {
  .btn__top--active {
    bottom: 2rem;
  }
}

@include breakpoint(550) {
  body .content,
  .body-active .content {
    padding: 4rem 0.5rem;
  }
}

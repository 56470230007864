.prestation-content {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.prestation-content .owl-nav {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
}

.prestation-content .owl-prev,
.prestation-content .owl-next {
  position: absolute;
  top: 25%;
}

.prestation-content .owl-prev {
  left: -65px;
}
.prestation-content .owl-next {
  right: -65px;
}

.prestation-content .owl-prev span,
.prestation-content .owl-next span {
  font-size: 9rem;
}

.item-presta {
  width: 250px;
  text-align: center;
}

.presta-img {
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.presta-img_0 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-extensions-cils.jpg");
}
.presta-img_1 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-beaute-naturelle.jpg");
}
.presta-img_2 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-homme.jpg");
}
.presta-img_3 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-soiree.jpg");
}
.presta-img_4 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-essai.jpg");
}
.presta-img_5 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-jour-j.jpg");
}
.presta-img_6 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-retouche.jpg");
}
.presta-img_7 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-ceremonie.jpg");
}
.presta-img_8 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-cour.jpg");
}
.presta-img_9 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-evjf.jpg");
}
.presta-img_10 {
  background-image: url("../../../images/maquilleur-professionnel/prestations/prestation-millenium.jpg");
}

.presta-titre {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.95rem;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 5px 0;
  line-height: 24px;
  background: rgba(255, 255, 255, 0.8);
}

.item-link {
  position: relative;
  display: inline-block;
  font-family: var(--font-titre);
  font-size: 2.9rem;
  line-height: 40px;
  padding: 12px 0;
  letter-spacing: 1px;
  margin: 7px 0;
  background-image: url("../../../images/maquilleur-professionnel/background-menu-argent.png");
  background-size: 110px auto;
  background-repeat: no-repeat;
  background-position: center center;
  &:hover {
    background-size: 0 0;
  }
}

.item-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 50%;
  margin-left: -55px;
  width: 0;
  height: 0;
  background-image: url("../../../images//maquilleur-professionnel/background-menu-or.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 300ms;
}

.item-link:hover::before {
  width: 110px;
  height: 48px;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 500px) {
  .prestation-content {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    .owl-carousel {
      width: 80%;
      margin: 0 auto;
    }
  }

  .prestation-content .owl-prev span,
  .prestation-content .owl-next span {
    font-size: 5rem;
  }

  .prestation-content .owl-prev {
    left: -12%;
  }
  .prestation-content .owl-next {
    right: -12%;
  }
}

@media screen and (max-width: 400px) {
  .prestation-content {
    .owl-carousel {
      width: 95%;
    }
    .owl-nav {
      @include hidden;
    }
  }
}

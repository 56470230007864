.a-propos-image,
.a-propos-info {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 45%;
}

.a-propos-image {
  margin-right: 25px;
}

.pb-cite {
  display: block;
  font-style: normal;
  margin-top: 25px;
  color: var(--color-1);
  font-size: 3rem;
  font-family: var(--font-titre);
}

.pb-cite i,
.pb-cite svg {
  display: block;
  margin: 0 auto;
  color: var(--color-dark);
  text-align: center;
}

.pb-cite-coco {
  display: block;
  margin-bottom: 50px;
  color: var(--gray-4);
  font-style: italic;
  font-size: 1rem;
}

.a-propos-info img {
  border: 20px solid white;
  margin-left: -25%;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1500px) {
  .a-propos-info p {
    font-size: 1.2rem;
  }
  .pb-cite {
    line-height: 50px;
  }
  .pb-cite-coco {
    margin-bottom: 10px;
  }
  .pb-title-big {
    font-size: 11rem;
  }
  .pb-title-slogan {
    top: -120px;
  }
}
@media screen and (max-width: 1300px) {
  .a-propos-image_2 {
    @include hidden;
  }
}

@media screen and (max-width: 1000px) {
  .a-propos-image,
  .a-propos-info {
    display: block;
    text-align: center;
    width: 100%;
  }
  .a-propos-image_1 {
    max-width: 550px;
  }
  .a-propos-info {
    margin-top: 55px;
  }

  .a-propos-info p,
  .pb-cite,
  .pb-cite-coco {
    padding: 0 5%;
  }
}

@media screen and (max-width: 800px) {
  .contenu {
    padding: 50px 0;
  }
  .a-propos-image_1 {
    max-width: 90%;
  }
  .pb-title {
    font-size: 2rem;
  }
  .pb-title-big {
    font-size: 8rem;
  }
  .pb-title-slogan {
    top: -90px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 500px) {
  .pb-title-slogan {
    font-size: 1.4rem;
  }
}
